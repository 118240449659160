@font-face {
  font-family: "Porsche-UI-Icons";
  src: url("./fonts/iconfont_DA_center/pag-iconfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Porsche-UI-Icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_up:before {
  content: "\e900";
}
.icon-battery_0:before {
  content: "\e901";
}
.icon-battery_1:before {
  content: "\e902";
}
.icon-battery_2:before {
  content: "\e903";
}
.icon-battery_3:before {
  content: "\e904";
}
.icon-battery_4:before {
  content: "\e905";
}
.icon-battery_blank:before {
  content: "\e906";
}
.icon-cancel:before {
  content: "\e907";
}
.icon-cell_phone:before {
  content: "\e908";
}
.icon-delete_numbers:before {
  content: "\e909";
}
.icon-delete_numbers_arabic:before {
  content: "\e90a";
}
.icon-group:before {
  content: "\e90b";
}
.icon-information:before {
  content: "\e90c";
}
.icon-information_outline:before {
  content: "\e90d";
}
.icon-phone_add_call:before {
  content: "\e90e";
}
.icon-phone_hang_up:before {
  content: "\e90f";
}
.icon-phone_incoming_call:before {
  content: "\e910";
}
.icon-phone_missed_call:before {
  content: "\e911";
}
.icon-phone_outgoing_call:before {
  content: "\e912";
}
.icon-art_culture:before {
  content: "\e913";
}
.icon-charging_station:before {
  content: "\e914";
}
.icon-destination_flag:before {
  content: "\e915";
}
.icon-dls_top:before {
  content: "\e916";
}
.icon-stopover_flag_add:before {
  content: "\e917";
}
.icon-laptop:before {
  content: "\e918";
}
.icon-microphone_hand:before {
  content: "\e919";
}
.icon-nightlife:before {
  content: "\e91a";
}
.icon-parking:before {
  content: "\e91b";
}
.icon-sports:before {
  content: "\e91c";
}
.icon-tour:before {
  content: "\e91d";
}
.icon-bluetooth:before {
  content: "\e91e";
}
.icon-air_footwell_left_cold:before {
  content: "\e91f";
}
.icon-channel:before {
  content: "\e920";
}
.icon-note_circle:before {
  content: "\e921";
}
.icon-cover:before {
  content: "\e922";
}
.icon-note_add_favorite:before {
  content: "\e923";
}
.icon-pause:before {
  content: "\e924";
}
.icon-play:before {
  content: "\e925";
}
.icon-playlist:before {
  content: "\e926";
}
.icon-fm_linking_list:before {
  content: "\e927";
}
.icon-radio_no_signal:before {
  content: "\e928";
}
.icon-repeat:before {
  content: "\e929";
}
.icon-replay:before {
  content: "\e92a";
}
.icon-shuffle:before {
  content: "\e92b";
}
.icon-skip_backward:before {
  content: "\e92c";
}
.icon-skip_forward:before {
  content: "\e92d";
}
.icon-spotify:before {
  content: "\e92e";
}
.icon-star:before {
  content: "\e92f";
}
.icon-star_outline:before {
  content: "\e930";
}
.icon-stop_scan:before {
  content: "\e931";
}
.icon-tag:before {
  content: "\e932";
}
.icon-usb:before {
  content: "\e933";
}
.icon-magnify_glass:before {
  content: "\e934";
}
.icon-mute:before {
  content: "\e935";
}
.icon-my_location:before {
  content: "\e936";
}
.icon-north_arrow:before {
  content: "\e937";
}
.icon-readout:before {
  content: "\e938";
}
.icon-selena:before {
  content: "\e939";
}
.icon-weather:before {
  content: "\e93a";
}
.icon-apple_carplay:before {
  content: "\e93b";
}
.icon-arrow_open_left:before {
  content: "\e93c";
}
.icon-arrow_open_right:before {
  content: "\e93d";
}
.icon-tune_keypads:before {
  content: "\e93e";
}
.icon-microphone:before {
  content: "\e93f";
}
.icon-microphone_crossed:before {
  content: "\e940";
}
.icon-at:before {
  content: "\e941";
}
.icon-note:before {
  content: "\e942";
}
.icon-option_open:before {
  content: "\e943";
}
.icon-voice_mail:before {
  content: "\e944";
}
.icon-car_limousine:before {
  content: "\e945";
}
.icon-car_limousine_niveau:before {
  content: "\e946";
}
.icon-car_limousine_spoiler:before {
  content: "\e947";
}
.icon-e_sound:before {
  content: "\e948";
}
.icon-pasm:before {
  content: "\e949";
}
.icon-dot:before {
  content: "\e94a";
}
.icon-enter:before {
  content: "\e94b";
}
.icon-enter_arabic:before {
  content: "\e94c";
}
.icon-globe:before {
  content: "\e94d";
}
.icon-handwriting:before {
  content: "\e94e";
}
.icon-keyboard:before {
  content: "\e94f";
}
.icon-keyboard_hide:before {
  content: "\e950";
}
.icon-shift:before {
  content: "\e951";
}
.icon-space:before {
  content: "\e952";
}
.icon-speller_characters:before {
  content: "\e953";
}
.icon-speller_delete_numbers:before {
  content: "\e954";
}
.icon-speller_delete_numbers_arabic:before {
  content: "\e955";
}
.icon-speller_letters:before {
  content: "\e956";
}
.icon-speller_numbers:before {
  content: "\e957";
}
.icon-privacy:before {
  content: "\e958";
}
.icon-privacy_nad:before {
  content: "\e959";
}
.icon-recording:before {
  content: "\e95a";
}
.icon-wlan:before {
  content: "\e95b";
}
.icon-climate_style_left:before {
  content: "\e95c";
}
.icon-circle_outline:before {
  content: "\e95d";
}
.icon-air_off:before {
  content: "\e95e";
}
.icon-parking_cross_backward_left:before {
  content: "\e95f";
}
.icon-parking_cross_backward_right:before {
  content: "\e960";
}
.icon-parking_cross_forward_left:before {
  content: "\e961";
}
.icon-parking_cross_forward_right:before {
  content: "\e962";
}
.icon-parking_garage:before {
  content: "\e963";
}
.icon-parking_parallel_left:before {
  content: "\e964";
}
.icon-ac:before {
  content: "\e965";
}
.icon-ac_eco:before {
  content: "\e966";
}
.icon-setting:before {
  content: "\e967";
}
.icon-car_front_j1:before {
  content: "\e968";
}
.icon-delete_entry:before {
  content: "\e969";
}
.icon-network_0:before {
  content: "\e96a";
}
.icon-4G:before {
  content: "\e96b";
}
.icon-edge:before {
  content: "\e96c";
}
.icon-etc_active:before {
  content: "\e96d";
}
.icon-etc_error:before {
  content: "\e96e";
}
.icon-home:before {
  content: "\e96f";
}
.icon-ionisation:before {
  content: "\e970";
}
.icon-lte:before {
  content: "\e971";
}
.icon-geo_localization:before {
  content: "\e972";
}
.icon-cell_phone_crossed:before {
  content: "\e973";
}
.icon-circle_crossed:before {
  content: "\e974";
}
.icon-connect_app:before {
  content: "\e975";
}
.icon-connectivity:before {
  content: "\e976";
}
.icon-connectivity_disabled:before {
  content: "\e977";
}
.icon-car_limousine_sound_off:before {
  content: "\e978";
}
.icon-3g:before {
  content: "\e979";
}
.icon-poi_filled:before {
  content: "\e97a";
}
.icon-parking_assistant:before {
  content: "\e97b";
}
.icon-parking_camera:before {
  content: "\e97c";
}
.icon-sim_card_privacy:before {
  content: "\e97d";
}
.icon-uniE97B:before {
  content: "\e97e";
}
.icon-satellite:before {
  content: "\e97f";
}
.icon-map_layer:before {
  content: "\e980";
}
.icon-destination_flag_arrow:before {
  content: "\e981";
}
.icon-stopover_flag_arrow:before {
  content: "\e982";
}
.icon-air_distance_filled:before {
  content: "\e983";
}
.icon-person:before {
  content: "\e984";
}
.icon-concierge:before {
  content: "\e985";
}
.icon-air_distance_to_poi:before {
  content: "\e986";
}
.icon-last_destination:before {
  content: "\e987";
}
.icon-address_home:before {
  content: "\e988";
}
.icon-address_work:before {
  content: "\e989";
}
.icon-bin:before {
  content: "\e98a";
}
.icon-edit:before {
  content: "\e98b";
}
.icon-channel_no_signal:before {
  content: "\e98c";
}
.icon-ipod:before {
  content: "\e98d";
}
.icon-epg:before {
  content: "\e98e";
}
.icon-folder:before {
  content: "\e98f";
}
.icon-fm_linking:before {
  content: "\e990";
}
.icon-online_linking:before {
  content: "\e991";
}
.icon-fast_backward:before {
  content: "\e992";
}
.icon-fast_forward:before {
  content: "\e993";
}
.icon-live:before {
  content: "\e994";
}
.icon-air_distribution_left:before {
  content: "\e995";
}
.icon-circle_filled:before {
  content: "\e996";
}
.icon-car_limousine_auto_recirculation:before {
  content: "\e997";
}
.icon-repeat_title:before {
  content: "\e998";
}
.icon-podcast:before {
  content: "\e999";
}
.icon-warning_filled:before {
  content: "\e99a";
}
.icon-aca_lks:before {
  content: "\e99b";
}
.icon-back_out_assistant:before {
  content: "\e99c";
}
.icon-cross_over_assistant:before {
  content: "\e99d";
}
.icon-lane_change_assist:before {
  content: "\e99e";
}
.icon-exit_warning:before {
  content: "\e99f";
}
.icon-traffic_assist_j1:before {
  content: "\e9a0";
}
.icon-emergency_assist_j1:before {
  content: "\e9a1";
}
.icon-scale_zoom_auto:before {
  content: "\e9a2";
}
.icon-scale_zoom_manually:before {
  content: "\e9a3";
}
.icon-3D_map:before {
  content: "\e9a4";
}
.icon-fm_dab:before {
  content: "\e9a5";
}
.icon-fm_dab_no_signal:before {
  content: "\e9a6";
}
.icon-fm_radio:before {
  content: "\e9a7";
}
.icon-fm_radio_no_signal:before {
  content: "\e9a8";
}
.icon-arrow_open_up:before {
  content: "\e9a9";
}
.icon-arrow_open_down:before {
  content: "\e9aa";
}
.icon-air_distribution_right:before {
  content: "\e9ab";
}
.icon-route:before {
  content: "\e9ac";
}
.icon-phone_receiver_green:before {
  content: "\e9ad";
}
.icon-message_outline:before {
  content: "\e9ae";
}
.icon-lane_departure_warning_system_j1:before {
  content: "\e9af";
}
.icon-emergency_assist:before {
  content: "\e9b0";
}
.icon-seat_heating_right:before {
  content: "\e9b1";
}
.icon-seat_heating_left:before {
  content: "\e9b2";
}
.icon-road:before {
  content: "\e9b3";
}
.icon-freeway:before {
  content: "\e9b4";
}
.icon-hov_avoid_not:before {
  content: "\e9b5";
}
.icon-freeway_toll:before {
  content: "\e9b6";
}
.icon-avoid_ferry_car_train_not:before {
  content: "\e9b7";
}
.icon-tunnel:before {
  content: "\e9b8";
}
.icon-altitude:before {
  content: "\e9b9";
}
.icon-ass_j1:before {
  content: "\e9ba";
}
.icon-pdc_mute:before {
  content: "\e9bb";
}
.icon-poi:before {
  content: "\e9bc";
}
.icon-stopover_flag:before {
  content: "\e9bd";
}
.icon-night_vision:before {
  content: "\e9be";
}
.icon-powermeter:before {
  content: "\e9bf";
}
.icon-air_style_right:before {
  content: "\e9c0";
}
.icon-notification:before {
  content: "\e9c1";
}
.icon-options_more:before {
  content: "\e9c2";
}
.icon-radio:before {
  content: "\e9c3";
}
.icon-phone_receiver:before {
  content: "\e9c4";
}
.icon-ass_basic:before {
  content: "\e9c5";
}
.icon-message_sent:before {
  content: "\e9c6";
}
.icon-message_inbox:before {
  content: "\e9c7";
}
.icon-message_outbox:before {
  content: "\e9c8";
}
.icon-message_draft:before {
  content: "\e9c9";
}
.icon-message_template:before {
  content: "\e9ca";
}
.icon-predictive_maintenance:before {
  content: "\e9cb";
}
.icon-update_center:before {
  content: "\e9cc";
}
.icon-speller_coordinates:before {
  content: "\e9cd";
}
.icon-electricity:before {
  content: "\e9ce";
}
.icon-wind:before {
  content: "\e9cf";
}
.icon-sun:before {
  content: "\e9d0";
}
.icon-rain:before {
  content: "\e9d1";
}
.icon-trailer_view:before {
  content: "\e9d2";
}
.icon-ipa:before {
  content: "\e9d3";
}
.icon-rtv_panorama_view:before {
  content: "\e9d4";
}
.icon-maneuver_assist_j1:before {
  content: "\e9d5";
}
.icon-cleaning_camera:before {
  content: "\e9d6";
}
.icon-message_write:before {
  content: "\e9d7";
}
.icon-stopover_flag_delete:before {
  content: "\e9d8";
}
.icon-ambient_light:before {
  content: "\e9d9";
}
.icon-four_squares:before {
  content: "\e9da";
}
.icon-cell_phone_link:before {
  content: "\e9db";
}
.icon-porsche_connect_app:before {
  content: "\e9dc";
}
.icon-trip_continuous:before {
  content: "\e9dd";
}
.icon-trip_personal:before {
  content: "\e9de";
}
.icon-trip_since_refuelling:before {
  content: "\e9df";
}
.icon-trip_since:before {
  content: "\e9e0";
}
.icon-PoweredByGracenote_Logo:before {
  content: "\e9e1";
}
.icon-phone_ring_tone:before {
  content: "\e9e2";
}
.icon-radionet_Logo:before {
  content: "\e9e3";
}
.icon-circle:before {
  content: "\e9e4";
}
.icon-camera:before {
  content: "\e9e5";
}
.icon-apple_icloud:before {
  content: "\e9e6";
}
.icon-more:before {
  content: "\e9e7";
}
.icon-update:before {
  content: "\e9e8";
}
.icon-addressbook:before {
  content: "\e9e9";
}
.icon-all:before {
  content: "\e9ea";
}
.icon-all_outline:before {
  content: "\e9eb";
}
.icon-asterisk:before {
  content: "\e9ec";
}
.icon-asterisk_asia:before {
  content: "\e9ed";
}
.icon-bubble_info:before {
  content: "\e9ee";
}
.icon-communication:before {
  content: "\e9ef";
}
.icon-comma_fullstop_hiragana:before {
  content: "\e9f0";
}
.icon-comma_fullstop_taiwan:before {
  content: "\e9f1";
}
.icon-comma_point_hiragana:before {
  content: "\e9f2";
}
.icon-destination_flag_stop:before {
  content: "\e9f3";
}
.icon-ipa_scanner:before {
  content: "\e9f4";
}
.icon-logo_sirius_xm_long:before {
  content: "\e9f5";
}
.icon-logo_sirius_xm_short:before {
  content: "\e9f6";
}
.icon-on_off:before {
  content: "\e9f7";
}
.icon-maneuver_assist_j1_off:before {
  content: "\e9f8";
}
.icon-hiragana_function_key:before {
  content: "\e9f9";
}
.icon-ipa_search_right:before {
  content: "\e9fa";
}
.icon-ipa_search_left:before {
  content: "\e9fb";
}
.icon-genre:before {
  content: "\e9fc";
}
.icon-family:before {
  content: "\e9fd";
}
.icon-knowledge:before {
  content: "\e9fe";
}
.icon-festival:before {
  content: "\e9ff";
}
.icon-list:before {
  content: "\ea00";
}
.icon-bookmark:before {
  content: "\ea01";
}
.icon-themes:before {
  content: "\ea02";
}
.icon-seat_right:before {
  content: "\ea03";
}
.icon-seat_left:before {
  content: "\ea04";
}
.icon-seat_ventilation_left:before {
  content: "\ea05";
}
.icon-seat_ventilation_right:before {
  content: "\ea06";
}
.icon-check:before {
  content: "\ea07";
}
.icon-plus:before {
  content: "\ea08";
}
.icon-pid_upcoming_events_right_curve:before {
  content: "\ea09";
}
.icon-pid_upcoming_events_branch_off_left:before {
  content: "\ea0a";
}
.icon-pid_upcoming_events_branch_off_right:before {
  content: "\ea0b";
}
.icon-pid_upcoming_events_highway_exit_left:before {
  content: "\ea0c";
}
.icon-pid_upcoming_events_highway_exit_right:before {
  content: "\ea0d";
}
.icon-pid_upcoming_events_junction:before {
  content: "\ea0e";
}
.icon-pid_upcoming_events_left_curve:before {
  content: "\ea0f";
}
.icon-pid_upcoming_events_priority_to_left_a:before {
  content: "\ea10";
}
.icon-pid_upcoming_events_priority_to_left_b:before {
  content: "\ea11";
}
.icon-pid_upcoming_events_priority_to_right_a:before {
  content: "\ea12";
}
.icon-pid_upcoming_events_priority_to_right_b:before {
  content: "\ea13";
}
.icon-other_road:before {
  content: "\ea14";
}
.icon-bypass:before {
  content: "\ea15";
}
.icon-ass_disabled_j1:before {
  content: "\ea16";
}
.icon-recuperation:before {
  content: "\ea17";
}
.icon-clock_time:before {
  content: "\ea18";
}
.icon-update_save:before {
  content: "\ea19";
}
.icon-arrow_down:before {
  content: "\ea1a";
}
.icon-climate:before {
  content: "\ea1b";
}
.icon-ipa_leave_left:before {
  content: "\ea1c";
}
.icon-ipa_leave_right:before {
  content: "\ea1d";
}
.icon-logo_radio_net_long:before {
  content: "\ea1e";
}
.icon-drag_vertical:before {
  content: "\ea1f";
}
.icon-road_side_assistance:before {
  content: "\ea20";
}
.icon-air_center_j1:before {
  content: "\ea21";
}
.icon-air_style_right_1:before {
  content: "\ea22";
}
.icon-air_style_right_2:before {
  content: "\ea23";
}
.icon-air_style_right_3:before {
  content: "\ea24";
}
.icon-air_style_left_1:before {
  content: "\ea25";
}
.icon-air_style_left_2:before {
  content: "\ea26";
}
.icon-air_style_left_3:before {
  content: "\ea27";
}
.icon-air_distribution_right_up2:before {
  content: "\ea28";
}
.icon-air_distribution_right_mid2:before {
  content: "\ea29";
}
.icon-air_supply_right_down:before {
  content: "\ea2a";
}
.icon-air_distribution_left_up2:before {
  content: "\ea2b";
}
.icon-air_distribution_left_mid2:before {
  content: "\ea2c";
}
.icon-air_distribution_left_down2:before {
  content: "\ea2d";
}
.icon-parking_parallel_right:before {
  content: "\ea2e";
}
.icon-camera_arrow:before {
  content: "\ea2f";
}
.icon-maximize:before {
  content: "\ea30";
}
.icon-minimize:before {
  content: "\ea31";
}
.icon-ac_max:before {
  content: "\ea32";
}
.icon-conference_call:before {
  content: "\ea33";
}
.icon-split_calls:before {
  content: "\ea34";
}
.icon-scale_zoom_stopover:before {
  content: "\ea35";
}
.icon-scale_zoom_destination:before {
  content: "\ea36";
}
.icon-date_day:before {
  content: "\ea37";
}
.icon-date_today:before {
  content: "\ea38";
}
.icon-date_weekend:before {
  content: "\ea39";
}
.icon-saved:before {
  content: "\ea3a";
}
.icon-logo_taycan:before {
  content: "\ea3b";
}
.icon-pilopa:before {
  content: "\ea3c";
}
.icon-logo_bose:before {
  content: "\ea3d";
}
.icon-swipe:before {
  content: "\ea3e";
}
.icon-logo_burmester:before {
  content: "\ea3f";
}
.icon-notification_extended:before {
  content: "\ea40";
}
.icon-lg_dr_e0:before {
  content: "\ea41";
}
.icon-lg_dr_c0:before {
  content: "\ea42";
}
.icon-lg_dr_a0:before {
  content: "\ea43";
}
.icon-lg_dr_92:before {
  content: "\ea44";
}
.icon-lg_dr_72:before {
  content: "\ea45";
}
.icon-lg_dr_60:before {
  content: "\ea46";
}
.icon-lg_dr_40:before {
  content: "\ea47";
}
.icon-lg_dr_20:before {
  content: "\ea48";
}
.icon-lg_dr_00:before {
  content: "\ea49";
}
.icon-lg_2s_dr_e0_mitte:before {
  content: "\ea4a";
}
.icon-lg_2s_dr_e0_links_lang:before {
  content: "\ea4b";
}
.icon-lg_2s_dr_e0_links_kurz:before {
  content: "\ea4c";
}
.icon-lg_2s_dr_c0_mitte:before {
  content: "\ea4d";
}
.icon-lg_2s_dr_c0_mitte_kurz:before {
  content: "\ea4e";
}
.icon-lg_2s_dr_c0_links_lang:before {
  content: "\ea4f";
}
.icon-lg_2s_dr_c0_links_kurz:before {
  content: "\ea50";
}
.icon-lg_2s_dr_a0_mitte:before {
  content: "\ea51";
}
.icon-lg_2s_dr_a0_mitte_kurz:before {
  content: "\ea52";
}
.icon-lg_2s_dr_a0_links_lang:before {
  content: "\ea53";
}
.icon-lg_2s_dr_a0_links_kurz:before {
  content: "\ea54";
}
.icon-lg_2s_dr_92_mitte:before {
  content: "\ea55";
}
.icon-lg_2s_dr_92_links:before {
  content: "\ea56";
}
.icon-lg_2s_dr_72_rechts:before {
  content: "\ea57";
}
.icon-lg_2s_dr_72_mitte:before {
  content: "\ea58";
}
.icon-lg_2s_dr_60_rechts_lang:before {
  content: "\ea59";
}
.icon-lg_2s_dr_60_rechts_kurz:before {
  content: "\ea5a";
}
.icon-lg_2s_dr_60_mitte:before {
  content: "\ea5b";
}
.icon-lg_2s_dr_60_mitte_kurz:before {
  content: "\ea5c";
}
.icon-lg_2s_dr_40_rechts_lang:before {
  content: "\ea5d";
}
.icon-lg_2s_dr_40_rechts_kurz:before {
  content: "\ea5e";
}
.icon-lg_2s_dr_40_mitte:before {
  content: "\ea5f";
}
.icon-lg_2s_dr_40_mitte_kurz:before {
  content: "\ea60";
}
.icon-lg_2s_dr_20_rechts_lang:before {
  content: "\ea61";
}
.icon-lg_2s_dr_20_rechts_kurz:before {
  content: "\ea62";
}
.icon-lg_2s_dr_20_mitte:before {
  content: "\ea63";
}
.icon-lg_2s_dr_00_rechts:before {
  content: "\ea64";
}
.icon-lg_2s_dr_00_links:before {
  content: "\ea65";
}
.icon-lg_3s_dr_e0_mitte:before {
  content: "\ea66";
}
.icon-lg_3s_dr_e0_mitte_lang:before {
  content: "\ea67";
}
.icon-lg_3s_dr_e0_links:before {
  content: "\ea68";
}
.icon-lg_3s_dr_e0_links_lang:before {
  content: "\ea69";
}
.icon-lg_3s_dr_c0_mitte_lang:before {
  content: "\ea6a";
}
.icon-lg_3s_dr_c0_mitte_kurz:before {
  content: "\ea6b";
}
.icon-lg_3s_dr_c0_links:before {
  content: "\ea6c";
}
.icon-lg_3s_dr_c0_links_lang:before {
  content: "\ea6d";
}
.icon-lg_3s_dr_c0_links_kurz:before {
  content: "\ea6e";
}
.icon-lg_3s_dr_a0_mitte:before {
  content: "\ea6f";
}
.icon-lg_3s_dr_a0_mitte_lang:before {
  content: "\ea70";
}
.icon-lg_3s_dr_a0_mitte_kurz:before {
  content: "\ea71";
}
.icon-lg_3s_dr_a0_links:before {
  content: "\ea72";
}
.icon-lg_3s_dr_a0_links_lang:before {
  content: "\ea73";
}
.icon-lg_3s_dr_a0_links_kurz:before {
  content: "\ea74";
}
.icon-lg_3s_dr_92_mitte:before {
  content: "\ea75";
}
.icon-lg_3s_dr_92_mitte_kurz:before {
  content: "\ea76";
}
.icon-lg_3s_dr_92_links:before {
  content: "\ea77";
}
.icon-lg_3s_dr_92_links_kurz:before {
  content: "\ea78";
}
.icon-lg_3s_dr_72_rechts:before {
  content: "\ea79";
}
.icon-lg_3s_dr_72_rechts_kurz:before {
  content: "\ea7a";
}
.icon-lg_3s_dr_72_mitte:before {
  content: "\ea7b";
}
.icon-lg_3s_dr_72_mitte_kurz:before {
  content: "\ea7c";
}
.icon-lg_3s_dr_60_rechts:before {
  content: "\ea7d";
}
.icon-lg_3s_dr_60_rechts_lang:before {
  content: "\ea7e";
}
.icon-lg_3s_dr_60_rechts_kurz:before {
  content: "\ea7f";
}
.icon-lg_3s_dr_60_mitte:before {
  content: "\ea80";
}
.icon-lg_3s_dr_60_mitte_lang:before {
  content: "\ea81";
}
.icon-lg_3s_dr_60_mitte_kurz:before {
  content: "\ea82";
}
.icon-lg_3s_dr_40_rechts:before {
  content: "\ea83";
}
.icon-lg_3s_dr_40_rechts_lang:before {
  content: "\ea84";
}
.icon-lg_3s_dr_40_rechts_kurz:before {
  content: "\ea85";
}
.icon-lg_3s_dr_40_mitte_lang:before {
  content: "\ea86";
}
.icon-lg_3s_dr_40_mitte_kurz:before {
  content: "\ea87";
}
.icon-lg_3s_dr_20_rechts:before {
  content: "\ea88";
}
.icon-lg_3s_dr_20_rechts_lang:before {
  content: "\ea89";
}
.icon-lg_3s_dr_20_mitte:before {
  content: "\ea8a";
}
.icon-lg_3s_dr_20_mitte_lang:before {
  content: "\ea8b";
}
.icon-lg_3s_dr_00_rechts:before {
  content: "\ea8c";
}
.icon-lg_3s_dr_00_mitte:before {
  content: "\ea8d";
}
.icon-lg_3s_dr_00_links:before {
  content: "\ea8e";
}
.icon-lg_special_lane_widget_points:before {
  content: "\ea8f";
}
.icon-connect:before {
  content: "\ea90";
}
.icon-export:before {
  content: "\ea91";
}
.icon-news_channel:before {
  content: "\ea92";
}
.icon-lg_special_buslane:before {
  content: "\ea93";
}
.icon-04_base_layer_RoundAbout:before {
  content: "\ea94";
}
.icon-04_00_p_TurnArrow_UTurnC0:before {
  content: "\ea95";
}
.icon-04_00_p_TurnArrow_UTurn40:before {
  content: "\ea96";
}
.icon-04_00_p_TurnArrow_StandardTurnE0:before {
  content: "\ea97";
}
.icon-04_00_p_TurnArrow_StandardTurnC0:before {
  content: "\ea98";
}
.icon-04_00_p_TurnArrow_StandardTurnA0:before {
  content: "\ea99";
}
.icon-04_00_p_TurnArrow_StandardTurn60:before {
  content: "\ea9a";
}
.icon-04_00_p_TurnArrow_StandardTurn40:before {
  content: "\ea9b";
}
.icon-04_00_p_TurnArrow_StandardTurn20:before {
  content: "\ea9c";
}
.icon-04_00_p_TurnArrow_StandardTurn00:before {
  content: "\ea9d";
}
.icon-04_00_p_TurnArrow_SquareTrsRightF0:before {
  content: "\ea9e";
}
.icon-04_00_p_TurnArrow_SquareTrsRightE0:before {
  content: "\ea9f";
}
.icon-04_00_p_TurnArrow_SquareTrsRightB0_D0:before {
  content: "\eaa0";
}
.icon-04_00_p_TurnArrow_SquareTrsRight90_A0:before {
  content: "\eaa1";
}
.icon-04_00_p_TurnArrow_SquareTrsRight80:before {
  content: "\eaa2";
}
.icon-04_00_p_TurnArrow_SquareTrsRight60_70:before {
  content: "\eaa3";
}
.icon-04_00_p_TurnArrow_SquareTrsRight30_50:before {
  content: "\eaa4";
}
.icon-04_00_p_TurnArrow_SquareTrsRight20:before {
  content: "\eaa5";
}
.icon-04_00_p_TurnArrow_SquareTrsRight10:before {
  content: "\eaa6";
}
.icon-04_00_p_TurnArrow_SquareTrsRight00:before {
  content: "\eaa7";
}
.icon-04_00_p_TurnArrow_SquareTrsLeftF0:before {
  content: "\eaa8";
}
.icon-04_00_p_TurnArrow_SquareTrsLeftE0:before {
  content: "\eaa9";
}
.icon-04_00_p_TurnArrow_SquareTrsLeftB0_D0:before {
  content: "\eaaa";
}
.icon-04_00_p_TurnArrow_SquareTrsLeft90_A0:before {
  content: "\eaab";
}
.icon-04_00_p_TurnArrow_SquareTrsLeft80:before {
  content: "\eaac";
}
.icon-04_00_p_TurnArrow_SquareTrsLeft60_70:before {
  content: "\eaad";
}
.icon-04_00_p_TurnArrow_SquareTrsLeft30_50:before {
  content: "\eaae";
}
.icon-04_00_p_TurnArrow_SquareTrsLeft20:before {
  content: "\eaaf";
}
.icon-04_00_p_TurnArrow_SquareTrsLeft10:before {
  content: "\eab0";
}
.icon-04_00_p_TurnArrow_SquareTrsLeft00:before {
  content: "\eab1";
}
.icon-04_00_p_TurnArrow_ServiceRoadRight00:before {
  content: "\eab2";
}
.icon-04_00_p_TurnArrow_ServiceRoadLeft00:before {
  content: "\eab3";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsRightD0_F0:before {
  content: "\eab4";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsRightC0:before {
  content: "\eab5";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsRight90_B0:before {
  content: "\eab6";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsRight80:before {
  content: "\eab7";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsRight50_70:before {
  content: "\eab8";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsRight40:before {
  content: "\eab9";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsRight10_30:before {
  content: "\eaba";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsRight00:before {
  content: "\eabb";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsLeftD0_F0:before {
  content: "\eabc";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsLeftC0:before {
  content: "\eabd";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsLeft90_B0:before {
  content: "\eabe";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsLeft80:before {
  content: "\eabf";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsLeft50_70:before {
  content: "\eac0";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsLeft40:before {
  content: "\eac1";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsLeft10_30:before {
  content: "\eac2";
}
.icon-04_00_p_TurnArrow_RoundAboutTrsLeft00:before {
  content: "\eac3";
}
.icon-04_00_p_TurnArrow_OffRoadD0_F0:before {
  content: "\eac4";
}
.icon-04_00_p_TurnArrow_OffRoadC0:before {
  content: "\eac5";
}
.icon-04_00_p_TurnArrow_OffRoad90_B0:before {
  content: "\eac6";
}
.icon-04_00_p_TurnArrow_OffRoad80:before {
  content: "\eac7";
}
.icon-04_00_p_TurnArrow_OffRoad50_70:before {
  content: "\eac8";
}
.icon-04_00_p_TurnArrow_OffRoad40:before {
  content: "\eac9";
}
.icon-04_00_p_TurnArrow_OffRoad10_30:before {
  content: "\eaca";
}
.icon-04_00_p_TurnArrow_OffRoad00:before {
  content: "\eacb";
}
.icon-04_00_p_TurnArrow_MichiganUTurnC0:before {
  content: "\eacc";
}
.icon-04_00_p_TurnArrow_MichiganUTurn40:before {
  content: "\eacd";
}
.icon-04_00_p_TurnArrow_ForkC0:before {
  content: "\eace";
}
.icon-04_00_p_TurnArrow_Fork40:before {
  content: "\eacf";
}
.icon-04_00_p_TurnArrow_FollowStreetFF:before {
  content: "\ead0";
}
.icon-04_00_p_TurnArrow_FollowStreetC0:before {
  content: "\ead1";
}
.icon-04_00_p_TurnArrow_FollowStreet40:before {
  content: "\ead2";
}
.icon-04_00_p_TurnArrow_ExitRightC0_E0:before {
  content: "\ead3";
}
.icon-04_00_p_TurnArrow_ExitRight00:before {
  content: "\ead4";
}
.icon-04_00_p_TurnArrow_ExitLeftt20_40:before {
  content: "\ead5";
}
.icon-04_00_p_TurnArrow_ExitLeft00:before {
  content: "\ead6";
}
.icon-04_00_p_TurnArrow_ChangeLaneC0:before {
  content: "\ead7";
}
.icon-04_00_p_TurnArrow_ChangeLane40:before {
  content: "\ead8";
}
.icon-arrow_open_full_right:before {
  content: "\ead9";
}
.icon-arrow_open_full_left:before {
  content: "\eada";
}
.icon-charge_timer:before {
  content: "\eadb";
}
.icon-pid_upcoming_events_traffic_jam_end:before {
  content: "\eadc";
}
.icon-hd_voice:before {
  content: "\eadd";
}
.icon-logo_hefeng:before {
  content: "\eade";
}
.icon-logo_auro_3d:before {
  content: "\eadf";
}
.icon-logo_powered_by_google:before {
  content: "\eae0";
}
.icon-b_call_premium:before {
  content: "\eae1";
}
.icon-online_radio:before {
  content: "\eae2";
}
.icon-video_image_fullscreen:before {
  content: "\eae3";
}
.icon-video_image_fullscreen_close:before {
  content: "\eae4";
}
.icon-easy_entry:before {
  content: "\eae5";
}
.icon-manual:before {
  content: "\eae6";
}
.icon-abs_failure:before {
  content: "\eae7";
}
.icon-abs_na:before {
  content: "\eae8";
}
.icon-steering_wheel:before {
  content: "\eae9";
}
.icon-acc:before {
  content: "\eaea";
}
.icon-airbag_warning:before {
  content: "\eaeb";
}
.icon-voltage:before {
  content: "\eaec";
}
.icon-brake_worn_linings:before {
  content: "\eaed";
}
.icon-brake_wear_na:before {
  content: "\eaee";
}
.icon-brake_failure:before {
  content: "\eaef";
}
.icon-brake_fluid_na:before {
  content: "\eaf0";
}
.icon-sos:before {
  content: "\eaf1";
}
.icon-parking_brake:before {
  content: "\eaf2";
}
.icon-car_limousine_warning:before {
  content: "\eaf3";
}
.icon-esp_psm_off:before {
  content: "\eaf4";
}
.icon-4WD:before {
  content: "\eaf5";
}
.icon-car_limousine_outside_temp:before {
  content: "\eaf6";
}
.icon-tempomat:before {
  content: "\eaf7";
}
.icon-seat_belt:before {
  content: "\eaf8";
}
.icon-hal_1_2:before {
  content: "\eaf9";
}
.icon-car_limousine_warning_hybrid:before {
  content: "\eafa";
}
.icon-pid:before {
  content: "\eafb";
}
.icon-childlock:before {
  content: "\eafc";
}
.icon-engine_coolant_level:before {
  content: "\eafd";
}
.icon-beam_levelling_control:before {
  content: "\eafe";
}
.icon-light_failure:before {
  content: "\eaff";
}
.icon-smart_key:before {
  content: "\eb00";
}
.icon-pasm_warning:before {
  content: "\eb01";
}
.icon-pdcc_j1:before {
  content: "\eb02";
}
.icon-pos_lever:before {
  content: "\eb03";
}
.icon-cross_lock:before {
  content: "\eb04";
}
.icon-warning_tire_pressure:before {
  content: "\eb05";
}
.icon-engine:before {
  content: "\eb06";
}
.icon-e_range:before {
  content: "\eb07";
}
.icon-lock_locked:before {
  content: "\eb08";
}
.icon-key:before {
  content: "\eb09";
}
.icon-service:before {
  content: "\eb0a";
}
.icon-voltage_high:before {
  content: "\eb0b";
}
.icon-voltage_none:before {
  content: "\eb0c";
}
.icon-filler_cap_electric_warning_right:before {
  content: "\eb0d";
}
.icon-coolant_temperature:before {
  content: "\eb0e";
}
.icon-traffic_sign_recognition_j1:before {
  content: "\eb0f";
}
.icon-front_window_wiping_water:before {
  content: "\eb10";
}
.icon-lim:before {
  content: "\eb11";
}
.icon-car_limousine_ready_cn:before {
  content: "\eb12";
}
.icon-electric_driving_mileage_of_hybrid_vehicles_mode_cn:before {
  content: "\eb13";
}
.icon-limited_performance_mode_power_cn:before {
  content: "\eb14";
}
.icon-limited_performance_mode_turtle_cn:before {
  content: "\eb15";
}
.icon-ev_charging_connector_lock_cn:before {
  content: "\eb16";
}
.icon-filler_cap_electric_warning_left:before {
  content: "\eb17";
}
.icon-car_limousine_system_failure_text_cn:before {
  content: "\eb18";
}
.icon-electric_motor_failure_cn:before {
  content: "\eb19";
}
.icon-sds_pickup_list_entry_1:before {
  content: "\eb1a";
}
.icon-sds_pickup_list_entry_2:before {
  content: "\eb1b";
}
.icon-sds_pickup_list_entry_3:before {
  content: "\eb1c";
}
.icon-sds_pickup_list_entry_4:before {
  content: "\eb1d";
}
.icon-propulsion_battery_temperature:before {
  content: "\eb1e";
}
.icon-parking_light:before {
  content: "\eb1f";
}
.icon-plug:before {
  content: "\eb20";
}
.icon-sidelights:before {
  content: "\eb21";
}
.icon-door_lock_limousine:before {
  content: "\eb22";
}
.icon-aca_hands_off:before {
  content: "\eb23";
}
.icon-fod:before {
  content: "\eb24";
}
.icon-gforce_lateral_acceleration:before {
  content: "\eb25";
}
.icon-gforce_acceleration:before {
  content: "\eb26";
}
.icon-gforce_delay:before {
  content: "\eb27";
}
.icon-filter:before {
  content: "\eb28";
}
.icon-timer:before {
  content: "\eb29";
}
.icon-organizer:before {
  content: "\eb2a";
}
.icon-source_bluetooth:before {
  content: "\eb2b";
}
.icon-poi_listing:before {
  content: "\eb2c";
}
.icon-soc_destination_4:before {
  content: "\eb2d";
}
.icon-soc_destination_3:before {
  content: "\eb2e";
}
.icon-charging_station_warning_outline:before {
  content: "\eb2f";
}
.icon-logo_cityseeker:before {
  content: "\eb30";
}
.icon-source_usb:before {
  content: "\eb31";
}
.icon-charging_station_warning:before {
  content: "\eb32";
}
.icon-add_bluetooth:before {
  content: "\eb33";
}
.icon-brake_key_failure:before {
  content: "\eb34";
}
.icon-soc_destination_1:before {
  content: "\eb35";
}
.icon-homelink_send:before {
  content: "\eb36";
}
.icon-tour_flag:before {
  content: "\eb37";
}
.icon-plim_three_digits:before {
  content: "\eb38";
}
.icon-plim_offset:before {
  content: "\eb39";
}
.icon-soc_destination_0:before {
  content: "\eb3a";
}
.icon-soc_destination_5:before {
  content: "\eb3b";
}
.icon-plim_two_digits:before {
  content: "\eb3c";
}
.icon-charging_inductive:before {
  content: "\eb3d";
}
.icon-soc_destination_2:before {
  content: "\eb3e";
}
.icon-soc_destination_blank:before {
  content: "\eb3f";
}
.icon-update_security:before {
  content: "\eb40";
}
.icon-icon-google_search:before {
  content: "\eb41";
}
.icon-recuperation_level_adaptive:before {
  content: "\eb42";
}
.icon-charging_active:before {
  content: "\eb43";
}
.icon-filler_cap_ac_left:before {
  content: "\eb44";
}
.icon-filler_cap_ac_right:before {
  content: "\eb45";
}
.icon-filler_cap_dc_left:before {
  content: "\eb46";
}
.icon-filler_cap_dc_right:before {
  content: "\eb47";
}
.icon-filler_cap_dcac_left:before {
  content: "\eb48";
}
.icon-filler_cap_dcac_right:before {
  content: "\eb49";
}
.icon-plug_left_ac_cn:before {
  content: "\eb4a";
}
.icon-plug_right_dc_cn:before {
  content: "\eb4b";
}
.icon-plug_short_left:before {
  content: "\eb4c";
}
.icon-plug_short_right:before {
  content: "\eb4d";
}
.icon-charge_premium:before {
  content: "\eb4e";
}
.icon-parking_plus:before {
  content: "\eb4f";
}
.icon-globe_na:before {
  content: "\eb50";
}
.icon-news:before {
  content: "\eb51";
}
.icon-air_footwell_left:before {
  content: "\eb52";
}
.icon-flight_info:before {
  content: "\eb53";
}
.icon-city_events:before {
  content: "\eb54";
}
.icon-air_footwell_right:before {
  content: "\eb55";
}
.icon-speak:before {
  content: "\eb56";
}
.icon-message:before {
  content: "\eb57";
}
.icon-sim_card:before {
  content: "\eb58";
}
.icon-homelink:before {
  content: "\eb59";
}
.icon-logo_qi:before {
  content: "\eb5a";
}
.icon-duration:before {
  content: "\eb5b";
}
.icon-seat_massage_strength_right:before {
  content: "\eb5c";
}
.icon-seat_massage_strength_left:before {
  content: "\eb5d";
}
.icon-seat_massage_strength_5_right:before {
  content: "\eb5e";
}
.icon-seat_massage_strength_5_left:before {
  content: "\eb5f";
}
.icon-seat_massage_strength_4_right:before {
  content: "\eb60";
}
.icon-seat_massage_strength_4_left:before {
  content: "\eb61";
}
.icon-seat_massage_strength_3_right:before {
  content: "\eb62";
}
.icon-seat_massage_strength_3_left:before {
  content: "\eb63";
}
.icon-seat_massage_strength_2_right:before {
  content: "\eb64";
}
.icon-seat_massage_strength_2_left:before {
  content: "\eb65";
}
.icon-seat_massage_strength_1_right:before {
  content: "\eb66";
}
.icon-seat_massage_strength_1_left:before {
  content: "\eb67";
}
.icon-seat_massage_right:before {
  content: "\eb68";
}
.icon-seat_massage_left:before {
  content: "\eb69";
}
.icon-e_ac:before {
  content: "\eb6a";
}
.icon-ac_eco_plus:before {
  content: "\eb6b";
}
.icon-seat_comfort_left:before {
  content: "\eb6c";
}
.icon-seat_comfort_codriver_left:before {
  content: "\eb6d";
}
.icon-seat_config_codriver_left:before {
  content: "\eb6e";
}
.icon-seat_easy_entry_left:before {
  content: "\eb6f";
}
.icon-logo_cityseeker_skyline:before {
  content: "\eb70";
}
.icon-logo_yellowmap:before {
  content: "\eb71";
}
.icon-destination_flag_left:before {
  content: "\eb72";
}
.icon-destination_flag_right:before {
  content: "\eb73";
}
.icon-person_login:before {
  content: "\eb74";
}
.icon-car_limousine_hood:before {
  content: "\eb75";
}
.icon-car_sport_trunk_close_arrow:before {
  content: "\eb76";
}
.icon-car_sport_trunk_open_arrow:before {
  content: "\eb77";
}
.icon-plug_ccs_type2:before {
  content: "\eb78";
}
.icon-parking_brake_na:before {
  content: "\eb79";
}
.icon-money:before {
  content: "\eb7a";
}
.icon-plug_chademo:before {
  content: "\eb7b";
}
.icon-plug_iec_type1:before {
  content: "\eb7c";
}
.icon-plug_iec_type2:before {
  content: "\eb7d";
}
.icon-plug_ccs_type_1:before {
  content: "\eb7e";
}
.icon-plug_gbt_20234_2_cn:before {
  content: "\eb7f";
}
.icon-plug_gbt_20234_3_cn:before {
  content: "\eb80";
}
.icon-stopover_flag_right_label:before {
  content: "\eb81";
}
.icon-stopover_flag_right:before {
  content: "\eb82";
}
.icon-stopover_flag_left:before {
  content: "\eb83";
}
.icon-stopover_flag_left_label:before {
  content: "\eb84";
}
.icon-04_base_layer_Square:before {
  content: "\eb85";
}
.icon-home_sync:before {
  content: "\eb86";
}
.icon-phone_switch_call:before {
  content: "\eb87";
}
.icon-rating_cityseeker_0_5:before {
  content: "\eb88";
}
.icon-rating_cityseeker_0:before {
  content: "\eb89";
}
.icon-rating_cityseeker_1_5:before {
  content: "\eb8a";
}
.icon-rating_cityseeker_1:before {
  content: "\eb8b";
}
.icon-rating_cityseeker_2_5:before {
  content: "\eb8c";
}
.icon-rating_cityseeker_2:before {
  content: "\eb8d";
}
.icon-rating_cityseeker_3_5:before {
  content: "\eb8e";
}
.icon-rating_cityseeker_3:before {
  content: "\eb8f";
}
.icon-rating_cityseeker_3star_0_5:before {
  content: "\eb90";
}
.icon-rating_cityseeker_3star_0:before {
  content: "\eb91";
}
.icon-rating_cityseeker_3star_1_5:before {
  content: "\eb92";
}
.icon-rating_cityseeker_3star_1:before {
  content: "\eb93";
}
.icon-rating_cityseeker_3star_2_5:before {
  content: "\eb94";
}
.icon-rating_cityseeker_3star_2:before {
  content: "\eb95";
}
.icon-rating_cityseeker_3star_3:before {
  content: "\eb96";
}
.icon-rating_cityseeker_4_5:before {
  content: "\eb97";
}
.icon-rating_cityseeker_4:before {
  content: "\eb98";
}
.icon-rating_cityseeker_5:before {
  content: "\eb99";
}
.icon-sxm_possession:before {
  content: "\eb9a";
}
.icon-codriver_cockpit:before {
  content: "\eb9b";
}
.icon-traffic_antenna:before {
  content: "\eb9c";
}
.icon-logo_hd_radio:before {
  content: "\eb9d";
}
.icon-privacy_data:before {
  content: "\eb9e";
}
.icon-privacy_data_tracking:before {
  content: "\eb9f";
}
.icon-privacy_data_gps:before {
  content: "\eba0";
}
.icon-data_tracking:before {
  content: "\eba1";
}
.icon-air_footwell_left_warm:before {
  content: "\eba2";
}
.icon-air_footwell_right_cold:before {
  content: "\eba3";
}
.icon-air_footwell_right_warm:before {
  content: "\eba4";
}
.icon-globe_small_na:before {
  content: "\eba5";
}
.icon-globe_small:before {
  content: "\eba6";
}
.icon-sxm_for_you:before {
  content: "\eba7";
}
.icon-sxm_notification:before {
  content: "\eba8";
}
.icon-sxm_on_demand:before {
  content: "\eba9";
}
.icon-apple_add_to_playlist:before {
  content: "\ebaa";
}
.icon-apple_create_station:before {
  content: "\ebab";
}
.icon-apple_library:before {
  content: "\ebac";
}
.icon-apple_lyrics:before {
  content: "\ebad";
}
.icon-apple_play_later:before {
  content: "\ebae";
}
.icon-apple_play_next:before {
  content: "\ebaf";
}
.icon-apple_remove_from_playlist:before {
  content: "\ebb0";
}
.icon-logo_apple_music:before {
  content: "\ebb1";
}
.icon-qr_code_na:before {
  content: "\ebb2";
}
.icon-bubble_recommendations:before {
  content: "\ebb3";
}
.icon-charging_target_state:before {
  content: "\ebb4";
}
.icon-charging_profile:before {
  content: "\ebb5";
}
.icon-parking_forward:before {
  content: "\ebb6";
}
.icon-plug_small:before {
  content: "\ebb7";
}
.icon-logo_feedly:before {
  content: "\ebb8";
}
.icon-filler_cap_mechanic_warning_right:before {
  content: "\ebb9";
}
.icon-filler_cap_mechanic_warning_left:before {
  content: "\ebba";
}
.icon-e_range_nav:before {
  content: "\ebbb";
}
.icon-box:before {
  content: "\ebbc";
}
.icon-box_checked:before {
  content: "\ebbd";
}
.icon-box_checked_empty:before {
  content: "\ebbe";
}
.icon-radio_button:before {
  content: "\ebbf";
}
.icon-radio_button_checked:before {
  content: "\ebc0";
}
.icon-rear_window_defrost:before {
  content: "\ebc1";
}
.icon-front_window_defrost:before {
  content: "\ebc2";
}
.icon-skip_15s_backward:before {
  content: "\ebc3";
}
.icon-skip_15s_forward:before {
  content: "\ebc4";
}
.icon-apple_explicit:before {
  content: "\ebc5";
}
.icon-assist_on_off:before {
  content: "\ebc6";
}
.icon-e_range_cn:before {
  content: "\ebc7";
}
.icon-skip_30s_backward:before {
  content: "\ebc8";
}
.icon-skip_30s_forward:before {
  content: "\ebc9";
}
.icon-sxm_fast_backward:before {
  content: "\ebca";
}
.icon-sxm_fast_forward:before {
  content: "\ebcb";
}
.icon-sxm_notification_outline:before {
  content: "\ebcc";
}
.icon-lg_special_carpool:before {
  content: "\ebcd";
}
.icon-eye:before {
  content: "\ebce";
}
.icon-eye_off:before {
  content: "\ebcf";
}
.icon-subject:before {
  content: "\ebd0";
}
.icon-homelink_send_1:before {
  content: "\ebd1";
}
.icon-homelink_send_2:before {
  content: "\ebd2";
}
.icon-homelink_send_3:before {
  content: "\ebd3";
}
.icon-apple_love:before {
  content: "\ebd4";
}
.icon-apple_dislike:before {
  content: "\ebd5";
}
.icon-trip:before {
  content: "\ebd6";
}
.icon-traffic_info_outline_ece:before {
  content: "\ebd7";
}
.icon-traffic_info_outline_nar:before {
  content: "\ebd8";
}
.icon-air:before {
  content: "\ebd9";
}
.icon-base_mode_menu:before {
  content: "\ebda";
}
.icon-porsche_labs:before {
  content: "\ebdb";
}
.icon-logo_navitime:before {
  content: "\ebdc";
}
.icon-logo_autonavi:before {
  content: "\ebdd";
}
.icon-lg_2s_dr_40_mitte_kurz_n:before {
  content: "\ebde";
}
.icon-lg_2s_dr_60_mitte_n:before {
  content: "\ebdf";
}
.icon-lg_2s_dr_a0_mitte_n:before {
  content: "\ebe0";
}
.icon-lg_2s_dr_c0_mitte_kurz_n:before {
  content: "\ebe1";
}
.icon-lg_3s_dr_20_rechts_lang_n:before {
  content: "\ebe2";
}
.icon-lg_3s_dr_20_rechts_n:before {
  content: "\ebe3";
}
.icon-lg_3s_dr_40_rechts_kurz_n:before {
  content: "\ebe4";
}
.icon-lg_3s_dr_40_rechts_lang_n:before {
  content: "\ebe5";
}
.icon-lg_3s_dr_40_rechts_n:before {
  content: "\ebe6";
}
.icon-lg_3s_dr_c0_links_kurz_n:before {
  content: "\ebe7";
}
.icon-lg_3s_dr_c0_links_lang_n:before {
  content: "\ebe8";
}
.icon-lg_3s_dr_c0_links_n:before {
  content: "\ebe9";
}
.icon-lg_3s_dr_e0_links_lang_n:before {
  content: "\ebea";
}
.icon-lg_3s_dr_e0_links_n:before {
  content: "\ebeb";
}
.icon-lg_dr_40_n:before {
  content: "\ebec";
}
.icon-lg_dr_60_n:before {
  content: "\ebed";
}
.icon-lg_dr_a0_n:before {
  content: "\ebee";
}
.icon-lg_dr_c0_n:before {
  content: "\ebef";
}
.icon-option_view_1:before {
  content: "\ebf0";
}
.icon-Number_0:before {
  content: "\30";
}
.icon-Number_1:before {
  content: "\31";
}
.icon-Number_2:before {
  content: "\32";
}
.icon-Number_3:before {
  content: "\33";
}
.icon-Number_4:before {
  content: "\34";
}
.icon-Number_5:before {
  content: "\35";
}
.icon-Number_6:before {
  content: "\36";
}
.icon-Number_7:before {
  content: "\37";
}
.icon-Number_8:before {
  content: "\38";
}
.icon-Number_9:before {
  content: "\39";
}
.icon-Letter_A:before {
  content: "\41";
}
.icon-Letter_B:before {
  content: "\42";
}
.icon-Letter_C:before {
  content: "\43";
}
.icon-Letter_D:before {
  content: "\44";
}
.icon-Letter_E:before {
  content: "\45";
}
.icon-Letter_F:before {
  content: "\46";
}
.icon-Letter_G:before {
  content: "\47";
}
.icon-Letter_H:before {
  content: "\48";
}
.icon-Letter_I:before {
  content: "\49";
}
.icon-Letter_J:before {
  content: "\4a";
}
.icon-Letter_K:before {
  content: "\4b";
}
.icon-Letter_L:before {
  content: "\4c";
}
.icon-Letter_M:before {
  content: "\4d";
}
.icon-Letter_N:before {
  content: "\4e";
}
.icon-Letter_O:before {
  content: "\4f";
}
.icon-Letter_P:before {
  content: "\50";
}
.icon-Letter_Q:before {
  content: "\51";
}
.icon-Letter_R:before {
  content: "\52";
}
.icon-Letter_S:before {
  content: "\53";
}
.icon-Letter_T:before {
  content: "\54";
}
.icon-Letter_U:before {
  content: "\55";
}
.icon-Letter_V:before {
  content: "\56";
}
.icon-Letter_W:before {
  content: "\57";
}
.icon-Letter_X:before {
  content: "\58";
}
.icon-Letter_Y:before {
  content: "\59";
}
.icon-Letter_Z:before {
  content: "\5a";
}
.icon-Letter_A1:before {
  content: "\61";
}
.icon-Letter_B1:before {
  content: "\62";
}
.icon-Letter_C1:before {
  content: "\63";
}
.icon-Letter_D1:before {
  content: "\64";
}
.icon-Letter_E1:before {
  content: "\65";
}
.icon-Letter_F1:before {
  content: "\66";
}
.icon-Letter_G1:before {
  content: "\67";
}
.icon-Letter_H1:before {
  content: "\68";
}
.icon-Letter_I1:before {
  content: "\69";
}
.icon-Letter_J1:before {
  content: "\6a";
}
.icon-Letter_K1:before {
  content: "\6b";
}
.icon-Letter_L1:before {
  content: "\6c";
}
.icon-Letter_M1:before {
  content: "\6d";
}
.icon-Letter_N1:before {
  content: "\6e";
}
.icon-Letter_O1:before {
  content: "\6f";
}
.icon-Letter_P1:before {
  content: "\70";
}
.icon-Letter_Q1:before {
  content: "\71";
}
.icon-Letter_R1:before {
  content: "\72";
}
.icon-Letter_S1:before {
  content: "\73";
}
.icon-Letter_T1:before {
  content: "\74";
}
.icon-Letter_U1:before {
  content: "\75";
}
.icon-Letter_V1:before {
  content: "\76";
}
.icon-Letter_W1:before {
  content: "\77";
}
.icon-Letter_X1:before {
  content: "\78";
}
.icon-Letter_Y1:before {
  content: "\79";
}
.icon-Letter_Z1:before {
  content: "\7a";
}
.icon-abs_label_na:before {
  content: "\ebf2";
}
.icon-ac_symbol:before {
  content: "\ebf7";
}
.icon-acc_auto:before {
  content: "\ebfb";
}
.icon-acc_three_digits:before {
  content: "\ebfc";
}
.icon-acc_two_digits:before {
  content: "\ebfd";
}
.icon-acc_unset:before {
  content: "\ebfe";
}
.icon-acc_vehicle_ahead_three_digits:before {
  content: "\ebff";
}
.icon-acc_vehicle_ahead_two_digits:before {
  content: "\ec00";
}
.icon-acc_vehicle_ahead_unset:before {
  content: "\ec01";
}
.icon-ad_blue_service:before {
  content: "\ec03";
}
.icon-ad_blue:before {
  content: "\ec04";
}
.icon-air_center_off:before {
  content: "\ec0a";
}
.icon-air_center:before {
  content: "\ec0b";
}
.icon-air_distance_filled_small:before {
  content: "\ec0c";
}
.icon-air_distance_outline_small:before {
  content: "\ec0e";
}
.icon-air_distance_outline:before {
  content: "\ec0f";
}
.icon-air_distribution_left_down:before {
  content: "\ec11";
}
.icon-air_distribution_left_mid:before {
  content: "\ec12";
}
.icon-air_distribution_left_rear:before {
  content: "\ec13";
}
.icon-air_distribution_left_up:before {
  content: "\ec14";
}
.icon-air_distribution_right_down:before {
  content: "\ec16";
}
.icon-air_distribution_right_mid:before {
  content: "\ec17";
}
.icon-air_distribution_right_rear:before {
  content: "\ec18";
}
.icon-air_distribution_right_up:before {
  content: "\ec19";
}
.icon-air_outline:before {
  content: "\ec22";
}
.icon-air_quality_weather:before {
  content: "\ec23";
}
.icon-air_quality:before {
  content: "\ec24";
}
.icon-air_style_left:before {
  content: "\ec25";
}
.icon-airbag_child_disable:before {
  content: "\ec28";
}
.icon-airbag_disable:before {
  content: "\ec29";
}
.icon-airbag_side:before {
  content: "\ec2a";
}
.icon-airbag:before {
  content: "\ec2c";
}
.icon-am:before {
  content: "\ec30";
}
.icon-app_icon_electroKm:before {
  content: "\ec32";
}
.icon-apple_itunes_radio:before {
  content: "\ec35";
}
.icon-ara_parking:before {
  content: "\ec36";
}
.icon-arrow_both_ways_open:before {
  content: "\ec37";
}
.icon-arrow_both_ways:before {
  content: "\ec38";
}
.icon-arrow_down_outline_thin:before {
  content: "\ec39";
}
.icon-arrow_down_outline:before {
  content: "\ec3a";
}
.icon-arrow_forward:before {
  content: "\ec3c";
}
.icon-arrow_full_down:before {
  content: "\ec3d";
}
.icon-arrow_full_left:before {
  content: "\ec3e";
}
.icon-arrow_full_right:before {
  content: "\ec3f";
}
.icon-arrow_full_up:before {
  content: "\ec40";
}
.icon-arrow_left_outline_thin:before {
  content: "\ec41";
}
.icon-arrow_left_outline:before {
  content: "\ec42";
}
.icon-arrow_left_right_one_green:before {
  content: "\ec43";
}
.icon-arrow_left:before {
  content: "\ec44";
}
.icon-arrow_open_full_down:before {
  content: "\ec46";
}
.icon-arrow_open_full_up:before {
  content: "\ec49";
}
.icon-arrow_right_outline_outline_thin:before {
  content: "\ec4d";
}
.icon-arrow_right_outline:before {
  content: "\ec4e";
}
.icon-arrow_right:before {
  content: "\ec4f";
}
.icon-arrow_up_outline_thin:before {
  content: "\ec50";
}
.icon-arrow_up_outline:before {
  content: "\ec51";
}
.icon-arrows_down:before {
  content: "\ec53";
}
.icon-arrows_three_circle:before {
  content: "\ec54";
}
.icon-arrows_up:before {
  content: "\ec55";
}
.icon-ass_disabled_limousine:before {
  content: "\ec59";
}
.icon-ass_disabled_sport:before {
  content: "\ec5a";
}
.icon-ass_disabled_suv:before {
  content: "\ec5b";
}
.icon-ass_limousine:before {
  content: "\ec5d";
}
.icon-ass_sport:before {
  content: "\ec5e";
}
.icon-ass_suv:before {
  content: "\ec5f";
}
.icon-assist_settings_limousine:before {
  content: "\ec61";
}
.icon-assist_settings_sport:before {
  content: "\ec62";
}
.icon-assist_settings_suv:before {
  content: "\ec63";
}
.icon-attachment:before {
  content: "\ec67";
}
.icon-audio_book:before {
  content: "\ec68";
}
.icon-audio_volume:before {
  content: "\ec69";
}
.icon-auto_stop_label_na:before {
  content: "\ec6a";
}
.icon-auto_stop_na:before {
  content: "\ec6b";
}
.icon-auto:before {
  content: "\ec6c";
}
.icon-autopilot:before {
  content: "\ec6d";
}
.icon-aux_input:before {
  content: "\ec6e";
}
.icon-avoid_ferry_car_train:before {
  content: "\ec70";
}
.icon-AWD_menu:before {
  content: "\ec71";
}
.icon-AWD:before {
  content: "\ec72";
}
.icon-awv_prewarning:before {
  content: "\ec73";
}
.icon-back:before {
  content: "\ec76";
}
.icon-baidu:before {
  content: "\ec77";
}
.icon-balance:before {
  content: "\ec78";
}
.icon-base_mode_menu1:before {
  content: "\ec79";
}
.icon-batterie_0:before {
  content: "\ec7a";
}
.icon-batterie_1:before {
  content: "\ec7b";
}
.icon-batterie_2:before {
  content: "\ec7c";
}
.icon-batterie_3:before {
  content: "\ec7d";
}
.icon-batterie_4:before {
  content: "\ec7e";
}
.icon-battery_blank1:before {
  content: "\ec7f";
}
.icon-beam_clean:before {
  content: "\ec80";
}
.icon-beam_full:before {
  content: "\ec81";
}
.icon-beam_high_auto:before {
  content: "\ec82";
}
.icon-beam_levelling_control1:before {
  content: "\ec83";
}
.icon-beam_low:before {
  content: "\ec84";
}
.icon-bell:before {
  content: "\ec85";
}
.icon-bicycle:before {
  content: "\ec86";
}
.icon-bin1:before {
  content: "\ec87";
}
.icon-boost_gauge:before {
  content: "\ec8a";
}
.icon-brake_fluid_label_na:before {
  content: "\ec8e";
}
.icon-brake_label_na:before {
  content: "\ec91";
}
.icon-brake_na:before {
  content: "\ec92";
}
.icon-brake_parking_label_na:before {
  content: "\ec93";
}
.icon-brake_parking_na:before {
  content: "\ec94";
}
.icon-brake_wear_label_na:before {
  content: "\ec95";
}
.icon-brake:before {
  content: "\ec98";
}
.icon-bridge:before {
  content: "\ec99";
}
.icon-bubble_comment:before {
  content: "\ec9a";
}
.icon-bubble_language:before {
  content: "\ec9c";
}
.icon-bubble_recommendations1:before {
  content: "\ec9d";
}
.icon-calendar:before {
  content: "\eb2a";
}
.icon-call_data:before {
  content: "\eca0";
}
.icon-camera_outline:before {
  content: "\eca2";
}
.icon-car_back_limousine:before {
  content: "\eca6";
}
.icon-car_back_sport:before {
  content: "\eca7";
}
.icon-car_back_suv:before {
  content: "\eca8";
}
.icon-car_front_911:before {
  content: "\eca9";
}
.icon-car_front_cayenne:before {
  content: "\ecaa";
}
.icon-car_front_macan:before {
  content: "\ecac";
}
.icon-car_front_panamera:before {
  content: "\ecad";
}
.icon-car_generic_group:before {
  content: "\ecae";
}
.icon-car_generic_s:before {
  content: "\ecaf";
}
.icon-car_generic_xs:before {
  content: "\ecb0";
}
.icon-car_generic_xxs:before {
  content: "\ecb1";
}
.icon-car_generic:before {
  content: "\ecb2";
}
.icon-car_limousine_blinds_back:before {
  content: "\ecb4";
}
.icon-car_limousine_blinds_top:before {
  content: "\ecb5";
}
.icon-car_limousine_hdc:before {
  content: "\ecb6";
}
.icon-car_limousine_niveau_lift_vehicle:before {
  content: "\ecb8";
}
.icon-car_limousine_niveau_minus_one:before {
  content: "\ecb9";
}
.icon-car_limousine_niveau_minus_two:before {
  content: "\ecba";
}
.icon-car_limousine_niveau_plus_one:before {
  content: "\ecbb";
}
.icon-car_limousine_niveau_plus_two:before {
  content: "\ecbc";
}
.icon-car_limousine_niveau_sink_vehicle:before {
  content: "\ecbd";
}
.icon-car_limousine_open:before {
  content: "\ecbf";
}
.icon-car_limousine_rear_engine_lid:before {
  content: "\ecc2";
}
.icon-car_limousine_recirculation:before {
  content: "\ecc3";
}
.icon-car_limousine_roof_open:before {
  content: "\ecc4";
}
.icon-car_limousine_settings:before {
  content: "\ecc5";
}
.icon-car_limousine_sound_off_red:before {
  content: "\ecc6";
}
.icon-car_limousine_sunroof:before {
  content: "\ecc9";
}
.icon-car_limousine_trunk_close_arrow:before {
  content: "\eccb";
}
.icon-car_limousine_trunk_close:before {
  content: "\eccc";
}
.icon-car_limousine_trunk_open_arrow:before {
  content: "\eccd";
}
.icon-car_limousine_trunk_open:before {
  content: "\ecce";
}
.icon-car_limousine_trunk:before {
  content: "\eccf";
}
.icon-car_limousine_warning_red:before {
  content: "\ecd1";
}
.icon-car_sport_auto_recirculation:before {
  content: "\ecd4";
}
.icon-car_sport_convertible_open:before {
  content: "\ecd5";
}
.icon-car_sport_convertible:before {
  content: "\ecd6";
}
.icon-car_sport_hdc:before {
  content: "\ecd7";
}
.icon-car_sport_hood:before {
  content: "\ecd8";
}
.icon-car_sport_niveau_minus_one:before {
  content: "\ecd9";
}
.icon-car_sport_niveau_minus_two:before {
  content: "\ecda";
}
.icon-car_sport_niveau_plus_one:before {
  content: "\ecdb";
}
.icon-car_sport_niveau_plus_two:before {
  content: "\ecdc";
}
.icon-car_sport_niveau_sink_vehicle:before {
  content: "\ecdd";
}
.icon-car_sport_niveau:before {
  content: "\ecde";
}
.icon-car_sport_outside_temp:before {
  content: "\ecdf";
}
.icon-car_sport_ready_cn:before {
  content: "\ece0";
}
.icon-car_sport_rear_engine_lid:before {
  content: "\ece1";
}
.icon-car_sport_recirculation:before {
  content: "\ece2";
}
.icon-car_sport_roof_open:before {
  content: "\ece3";
}
.icon-car_sport_settings:before {
  content: "\ece4";
}
.icon-car_sport_sound_off_red:before {
  content: "\ece5";
}
.icon-car_sport_sound_off:before {
  content: "\ece6";
}
.icon-car_sport_spoiler_front:before {
  content: "\ece7";
}
.icon-car_sport_spoiler:before {
  content: "\ece8";
}
.icon-car_sport_sunroof:before {
  content: "\ece9";
}
.icon-car_sport_system_failure_text_cn:before {
  content: "\ecea";
}
.icon-car_sport_targa_hatch:before {
  content: "\eceb";
}
.icon-car_sport_targa_open:before {
  content: "\ecec";
}
.icon-car_sport_targa_trunk_close_arrow:before {
  content: "\eced";
}
.icon-car_sport_targa_trunk_open_arrow:before {
  content: "\ecee";
}
.icon-car_sport_targa_trunk:before {
  content: "\ecef";
}
.icon-car_sport_targa:before {
  content: "\ecf0";
}
.icon-car_sport_trunk_close:before {
  content: "\ecf2";
}
.icon-car_sport_trunk_open:before {
  content: "\ecf4";
}
.icon-car_sport_trunk:before {
  content: "\ecf5";
}
.icon-car_sport_warning_hybrid:before {
  content: "\ecf6";
}
.icon-car_sport_warning_red:before {
  content: "\ecf7";
}
.icon-car_sport_warning:before {
  content: "\ecf8";
}
.icon-car_sport:before {
  content: "\ecf9";
}
.icon-car_suv_auto_recirculation:before {
  content: "\ecfa";
}
.icon-car_suv_hdc:before {
  content: "\ecfb";
}
.icon-car_suv_hood:before {
  content: "\ecfc";
}
.icon-car_suv_niveau_lift_vehicle:before {
  content: "\ecfd";
}
.icon-car_suv_niveau_minus_one:before {
  content: "\ecfe";
}
.icon-car_suv_niveau_minus_two:before {
  content: "\ecff";
}
.icon-car_suv_niveau_plus_one:before {
  content: "\ed00";
}
.icon-car_suv_niveau_plus_two:before {
  content: "\ed01";
}
.icon-car_suv_niveau_sink_vehicle:before {
  content: "\ed02";
}
.icon-car_suv_niveau:before {
  content: "\ed03";
}
.icon-car_suv_offroad:before {
  content: "\ed04";
}
.icon-car_suv_outside_temp:before {
  content: "\ed05";
}
.icon-car_suv_ready_cn:before {
  content: "\ed06";
}
.icon-car_suv_rear_engine_lid:before {
  content: "\ed07";
}
.icon-car_suv_recirculation:before {
  content: "\ed08";
}
.icon-car_suv_roof_open:before {
  content: "\ed09";
}
.icon-car_suv_settings:before {
  content: "\ed0a";
}
.icon-car_suv_sound_off_red:before {
  content: "\ed0b";
}
.icon-car_suv_sound_off:before {
  content: "\ed0c";
}
.icon-car_suv_spoiler:before {
  content: "\ed0d";
}
.icon-car_suv_sunroof:before {
  content: "\ed0e";
}
.icon-car_suv_system_failure_text_cn:before {
  content: "\ed0f";
}
.icon-car_suv_trunk_close_arrow:before {
  content: "\ed10";
}
.icon-car_suv_trunk_close:before {
  content: "\ed11";
}
.icon-car_suv_trunk_open_arrow:before {
  content: "\ed12";
}
.icon-car_suv_trunk_open:before {
  content: "\ed13";
}
.icon-car_suv_trunk:before {
  content: "\ed14";
}
.icon-car_suv_warning_hybrid:before {
  content: "\ed15";
}
.icon-car_suv_warning_red:before {
  content: "\ed16";
}
.icon-car_suv_warning:before {
  content: "\ed17";
}
.icon-car_suv:before {
  content: "\ed18";
}
.icon-car_top_view:before {
  content: "\ed19";
}
.icon-ccp_final_destination_flag:before {
  content: "\ed1a";
}
.icon-ccp_stopover_flag:before {
  content: "\ed1b";
}
.icon-cell_phone_screen_this_way:before {
  content: "\ed1e";
}
.icon-charge_iso_arrow_left:before {
  content: "\ed22";
}
.icon-charge_iso:before {
  content: "\ed23";
}
.icon-charging_station_local:before {
  content: "\ed29";
}
.icon-charging_station_outline:before {
  content: "\ed2a";
}
.icon-charging_station_plug_outline:before {
  content: "\ed2b";
}
.icon-check1:before {
  content: "\ed30";
}
.icon-circle_check:before {
  content: "\ed32";
}
.icon-circle_crossed_small:before {
  content: "\ed33";
}
.icon-circle_half:before {
  content: "\ed35";
}
.icon-circle_parking_small:before {
  content: "\ed37";
}
.icon-circle_parking:before {
  content: "\ed38";
}
.icon-city:before {
  content: "\ed3b";
}
.icon-climate_sync:before {
  content: "\ed3d";
}
.icon-cloud:before {
  content: "\ed40";
}
.icon-comma_fullstop:before {
  content: "\ed42";
}
.icon-compass_mekka:before {
  content: "\ed44";
}
.icon-compass:before {
  content: "\ed45";
}
.icon-composer:before {
  content: "\ed46";
}
.icon-concert:before {
  content: "\ed47";
}
.icon-confirm_speed:before {
  content: "\ed4a";
}
.icon-confirm:before {
  content: "\ed4b";
}
.icon-context_apps:before {
  content: "\ed4f";
}
.icon-context_map:before {
  content: "\ed50";
}
.icon-control_j1:before {
  content: "\ed51";
}
.icon-control_limousine:before {
  content: "\ed52";
}
.icon-control_sport:before {
  content: "\ed53";
}
.icon-control_suv:before {
  content: "\ed54";
}
.icon-corrupted_data:before {
  content: "\ed56";
}
.icon-countries:before {
  content: "\ed57";
}
.icon-cover_compilations:before {
  content: "\ed58";
}
.icon-dab:before {
  content: "\ed5c";
}
.icon-dds:before {
  content: "\ed61";
}
.icon-delete_number_arabic:before {
  content: "\ed63";
}
.icon-delete_number:before {
  content: "\ed64";
}
.icon-destination_flag_delete:before {
  content: "\ed66";
}
.icon-device_link:before {
  content: "\ed6b";
}
.icon-diesel_preheat:before {
  content: "\ed6c";
}
.icon-dislike_amazon:before {
  content: "\ed6d";
}
.icon-display_settings:before {
  content: "\ed6e";
}
.icon-document:before {
  content: "\ed70";
}
.icon-door_ajar:before {
  content: "\ed71";
}
.icon-door_lock_sport:before {
  content: "\ed73";
}
.icon-door_lock_suv:before {
  content: "\ed74";
}
.icon-double_de-clutch:before {
  content: "\ed76";
}
.icon-drag:before {
  content: "\ed78";
}
.icon-drive_modes_menu:before {
  content: "\ed79";
}
.icon-dvd_changer:before {
  content: "\ed7b";
}
.icon-dvd_controller:before {
  content: "\ed7c";
}
.icon-dvd_menu:before {
  content: "\ed7d";
}
.icon-dvd:before {
  content: "\ed7e";
}
.icon-e_power_not_available:before {
  content: "\ed80";
}
.icon-e_power:before {
  content: "\ed81";
}
.icon-e_range_outline:before {
  content: "\ed83";
}
.icon-e_trip_continuous:before {
  content: "\ed86";
}
.icon-e_trip_since:before {
  content: "\ed87";
}
.icon-earth_map:before {
  content: "\ed88";
}
.icon-edge_roaming:before {
  content: "\ed89";
}
.icon-edit_outline:before {
  content: "\ed8b";
}
.icon-electric_driving_mileage_of_hybrid_vehicles_cn:before {
  content: "\ed8d";
}
.icon-electric_motor_failure_cn1:before {
  content: "\ed8f";
}
.icon-emergency_assist_off:before {
  content: "\ed91";
}
.icon-emergency_blinkers:before {
  content: "\ed93";
}
.icon-emoji_grinning_face:before {
  content: "\ed94";
}
.icon-engine_fan:before {
  content: "\ed96";
}
.icon-esp_off:before {
  content: "\ed9b";
}
.icon-esp_psm_off_label:before {
  content: "\ed9c";
}
.icon-esp_psm_sport_label:before {
  content: "\ed9e";
}
.icon-evaluation:before {
  content: "\eda2";
}
.icon-exhaust:before {
  content: "\eda3";
}
.icon-exit:before {
  content: "\eda5";
}
.icon-extended_map_menu:before {
  content: "\eda7";
}
.icon-flight_arrival:before {
  content: "\edb3";
}
.icon-flight_departure:before {
  content: "\edb4";
}
.icon-fm_dab_radio_no_signal:before {
  content: "\edb6";
}
.icon-fm_dab_radio:before {
  content: "\edb7";
}
.icon-fm:before {
  content: "\edbc";
}
.icon-freeway_avoid:before {
  content: "\edbf";
}
.icon-freeway_toll_avoid:before {
  content: "\edc0";
}
.icon-front_window_defrost_text:before {
  content: "\edc3";
}
.icon-front_window_heating:before {
  content: "\edc5";
}
.icon-front_window_wind_deflector:before {
  content: "\edc6";
}
.icon-front_window_wiper_windshield:before {
  content: "\edc7";
}
.icon-front_window_wiping_water_wiper:before {
  content: "\edc8";
}
.icon-fuel_outline:before {
  content: "\edca";
}
.icon-fuel_prices:before {
  content: "\edcb";
}
.icon-fuel_station_plus:before {
  content: "\edcc";
}
.icon-fuel_warning_outline:before {
  content: "\edcd";
}
.icon-fuel_warning:before {
  content: "\edce";
}
.icon-fuel:before {
  content: "\edcf";
}
.icon-general_source:before {
  content: "\edd0";
}
.icon-globe_xsmall:before {
  content: "\edd7";
}
.icon-go_to:before {
  content: "\edd9";
}
.icon-google_search:before {
  content: "\edda";
}
.icon-grounding_crossed_small:before {
  content: "\eddb";
}
.icon-grounding_crossed:before {
  content: "\eddc";
}
.icon-grounding_small:before {
  content: "\eddd";
}
.icon-grounding:before {
  content: "\edde";
}
.icon-group_vertical:before {
  content: "\eddf";
}
.icon-hand_picked:before {
  content: "\ede2";
}
.icon-headlight_flasher:before {
  content: "\ede5";
}
.icon-heart_outline:before {
  content: "\ede6";
}
.icon-heart:before {
  content: "\ede7";
}
.icon-heater_fan:before {
  content: "\ede8";
}
.icon-heater:before {
  content: "\ede9";
}
.icon-help_outline:before {
  content: "\edea";
}
.icon-help:before {
  content: "\edeb";
}
.icon-hold_label_na:before {
  content: "\eded";
}
.icon-hold_na:before {
  content: "\edee";
}
.icon-hold:before {
  content: "\edef";
}
.icon-home_filled:before {
  content: "\edf0";
}
.icon-horn:before {
  content: "\edf5";
}
.icon-hov_avoid:before {
  content: "\edf7";
}
.icon-humidity:before {
  content: "\edf8";
}
.icon-icy_road:before {
  content: "\edf9";
}
.icon-icy_wet_road:before {
  content: "\edfa";
}
.icon-impaired_hearing:before {
  content: "\edfb";
}
.icon-impaired_visually:before {
  content: "\edfc";
}
.icon-import_sound:before {
  content: "\edfd";
}
.icon-inno_drive:before {
  content: "\ee00";
}
.icon-interieur_sound_sport_plus:before {
  content: "\ee01";
}
.icon-interieur_sound_sport:before {
  content: "\ee02";
}
.icon-iptv:before {
  content: "\ee0b";
}
.icon-jokerkey_filled:before {
  content: "\ee0c";
}
.icon-jokerkey:before {
  content: "\ee0d";
}
.icon-jukebox:before {
  content: "\ee0e";
}
.icon-key_porsche:before {
  content: "\ee0f";
}
.icon-lane_departure_warning_system_limousine:before {
  content: "\ee16";
}
.icon-lane_departure_warning_system_sport:before {
  content: "\ee17";
}
.icon-lane_departure_warning_system_suv:before {
  content: "\ee18";
}
.icon-lap_load:before {
  content: "\ee19";
}
.icon-ldw_arrows:before {
  content: "\ee1c";
}
.icon-ldw:before {
  content: "\ee1d";
}
.icon-lift_limousine:before {
  content: "\ee1e";
}
.icon-lift_sport:before {
  content: "\ee1f";
}
.icon-lift_suv:before {
  content: "\ee20";
}
.icon-light_bulp:before {
  content: "\ee21";
}
.icon-light_master_switch_auto:before {
  content: "\ee23";
}
.icon-like_amazon:before {
  content: "\ee24";
}
.icon-lim_auto:before {
  content: "\ee25";
}
.icon-lim_three_digits:before {
  content: "\ee26";
}
.icon-lim_two_digits:before {
  content: "\ee27";
}
.icon-lim_unset:before {
  content: "\ee28";
}
.icon-limited_performance_mode_power_level_1:before {
  content: "\ee2b";
}
.icon-limited_performance_mode_power_level_2:before {
  content: "\ee2c";
}
.icon-limited_performance_mode_power_level_3:before {
  content: "\ee2d";
}
.icon-limited_performance_mode_power_level_low:before {
  content: "\ee2e";
}
.icon-list_menu:before {
  content: "\ee30";
}
.icon-lks_arrows:before {
  content: "\ee33";
}
.icon-lks:before {
  content: "\ee34";
}
.icon-lock_locked_outline_thin:before {
  content: "\ee35";
}
.icon-lock_locked_outline:before {
  content: "\ee36";
}
.icon-lock_open_outline_thin:before {
  content: "\ee38";
}
.icon-lock_open_outline:before {
  content: "\ee39";
}
.icon-lock_open:before {
  content: "\ee3a";
}
.icon-logbook:before {
  content: "\ee3b";
}
.icon-logo_amazon_music:before {
  content: "\ee3c";
}
.icon-logo_audible:before {
  content: "\ee3d";
}
.icon-logo_aupeo:before {
  content: "\ee3e";
}
.icon-logo_deezer:before {
  content: "\ee44";
}
.icon-logo_douban_fm:before {
  content: "\ee45";
}
.icon-logo_iheart_media:before {
  content: "\ee48";
}
.icon-logo_qingting:before {
  content: "\ee4b";
}
.icon-logo_radio_net:before {
  content: "\ee4c";
}
.icon-logo_rhapsody:before {
  content: "\ee4d";
}
.icon-logo_Spotify:before {
  content: "\ee50";
}
.icon-lte_roaming:before {
  content: "\ee52";
}
.icon-magnify_glass_minus:before {
  content: "\ee54";
}
.icon-magnify_glass_plus:before {
  content: "\ee55";
}
.icon-maneuver_assist_j1_disabled:before {
  content: "\ee57";
}
.icon-maneuver_assist_limousine:before {
  content: "\ee59";
}
.icon-maneuver_assist_sport:before {
  content: "\ee5a";
}
.icon-maneuver_assist_suv:before {
  content: "\ee5b";
}
.icon-manual_iso:before {
  content: "\ee5c";
}
.icon-map_3D:before {
  content: "\ee5e";
}
.icon-map_mode_menu:before {
  content: "\ee60";
}
.icon-message_forward:before {
  content: "\ee63";
}
.icon-message_outline_forward:before {
  content: "\ee66";
}
.icon-message_outline_write:before {
  content: "\ee67";
}
.icon-microphone_processing:before {
  content: "\ee6f";
}
.icon-minus:before {
  content: "\ec00";
}
.icon-mirror_collapse:before {
  content: "\ee73";
}
.icon-mirror_defrost:before {
  content: "\ee74";
}
.icon-mirror_left:before {
  content: "\ee75";
}
.icon-mirror_right:before {
  content: "\ee76";
}
.icon-missed_sign:before {
  content: "\ee77";
}
.icon-mode_change:before {
  content: "\ee78";
}
.icon-motor:before {
  content: "\ee7a";
}
.icon-mountain_pass_avoid:before {
  content: "\ee7b";
}
.icon-mountain_pass:before {
  content: "\ee7c";
}
.icon-music_archive:before {
  content: "\ee7d";
}
.icon-music_genre:before {
  content: "\ee7e";
}
.icon-music_guide:before {
  content: "\ee7f";
}
.icon-my_destination:before {
  content: "\ee81";
}
.icon-napster:before {
  content: "\ee83";
}
.icon-navigation:before {
  content: "\ee84";
}
.icon-nearest_channel:before {
  content: "\ee85";
}
.icon-network_3g_roaming:before {
  content: "\ee86";
}
.icon-network_3g:before {
  content: "\ee87";
}
.icon-network_4g_roaming:before {
  content: "\ee88";
}
.icon-network_4g:before {
  content: "\ee89";
}
.icon-new:before {
  content: "\ee8a";
}
.icon-night_vision_pedestrian_warning_off:before {
  content: "\ee8c";
}
.icon-night_vision_pedestrian_warning_on:before {
  content: "\ee8d";
}
.icon-no_reception:before {
  content: "\ee90";
}
.icon-notification_outline:before {
  content: "\ee95";
}
.icon-off:before {
  content: "\ee97";
}
.icon-oil_can:before {
  content: "\ee98";
}
.icon-oil_level:before {
  content: "\ee99";
}
.icon-oil_temperature:before {
  content: "\ee9a";
}
.icon-online_radio_no_signal:before {
  content: "\ee9d";
}
.icon-online_search:before {
  content: "\ee9f";
}
.icon-option_close:before {
  content: "\eea0";
}
.icon-option_sort:before {
  content: "\eea2";
}
.icon-option_view_0:before {
  content: "\eea3";
}
.icon-overview:before {
  content: "\eea8";
}
.icon-paragraph:before {
  content: "\eea9";
}
.icon-parking_assistant_off:before {
  content: "\eeaa";
}
.icon-parking_plus_v2:before {
  content: "\eeb6";
}
.icon-particulate_filter:before {
  content: "\eeb9";
}
.icon-pasm_narrow:before {
  content: "\eeba";
}
.icon-pasm_sport:before {
  content: "\eebb";
}
.icon-pasm_sportplus:before {
  content: "\eebc";
}
.icon-pasm_warning_v1:before {
  content: "\eebd";
}
.icon-pasm_wide:before {
  content: "\eebf";
}
.icon-pdc_unmute:before {
  content: "\eec3";
}
.icon-pdcc_limousine:before {
  content: "\eec5";
}
.icon-pdcc_sport:before {
  content: "\eec6";
}
.icon-pdcc_suv:before {
  content: "\eec7";
}
.icon-person_add:before {
  content: "\eec8";
}
.icon-person_escape_trunk:before {
  content: "\eec9";
}
.icon-person_escape:before {
  content: "\eeca";
}
.icon-person_logout:before {
  content: "\eecc";
}
.icon-person_outline:before {
  content: "\eecd";
}
.icon-person_tweets:before {
  content: "\eece";
}
.icon-person_walk:before {
  content: "\eecf";
}
.icon-phone_pause_call:before {
  content: "\eed6";
}
.icon-phone_reactivate_call:before {
  content: "\eed7";
}
.icon-phone_switch_call1:before {
  content: "\eed9";
}
.icon-phone:before {
  content: "\eeda";
}
.icon-picture_import:before {
  content: "\eedb";
}
.icon-picture:before {
  content: "\eedc";
}
.icon-pid_auto:before {
  content: "\eedd";
}
.icon-pid_permanent_offset_1-digit_minus:before {
  content: "\eede";
}
.icon-pid_permanent_offset_1-digit_plus:before {
  content: "\eedf";
}
.icon-pid_permanent_offset_2-digits_minus:before {
  content: "\eee0";
}
.icon-pid_permanent_offset_2-digits_plus:before {
  content: "\eee1";
}
.icon-pid_three_digits:before {
  content: "\eee2";
}
.icon-pid_two_digits:before {
  content: "\eee3";
}
.icon-pid_unset:before {
  content: "\eee4";
}
.icon-pid_upcoming_events_example_speed_limit:before {
  content: "\eee7";
}
.icon-pid_upcoming_events_prioritysign_priority_outline:before {
  content: "\eef0";
}
.icon-pid_upcoming_events_prioritysign_stop_outline:before {
  content: "\eef1";
}
.icon-pid_upcoming_events_right_off_way:before {
  content: "\eef3";
}
.icon-pid_upcoming_events_roundabout_traffic_sign:before {
  content: "\eef4";
}
.icon-pid_upcoming_events_speed_limit_generic:before {
  content: "\eef5";
}
.icon-pid_upcoming_events_town:before {
  content: "\eef6";
}
.icon-pid_upcoming_events_traffic_light:before {
  content: "\eef8";
}
.icon-pid_vehicle_ahead_auto:before {
  content: "\eef9";
}
.icon-pid_vehicle_ahead_three_digits:before {
  content: "\eefa";
}
.icon-pid_vehicle_ahead_two_digits:before {
  content: "\eefb";
}
.icon-pid_vehicle_ahead_unset:before {
  content: "\eefc";
}
.icon-placeholderSC:before {
  content: "\eeff";
}
.icon-play_cirle:before {
  content: "\ef00";
}
.icon-play_video:before {
  content: "\ef01";
}
.icon-plug_cee_blue:before {
  content: "\ef08";
}
.icon-plug_cee_red:before {
  content: "\ef09";
}
.icon-plug_powerline:before {
  content: "\ef0e";
}
.icon-plug_SchuKo:before {
  content: "\ef10";
}
.icon-plug_small_duration:before {
  content: "\ef13";
}
.icon-plug_small_outline:before {
  content: "\ef14";
}
.icon-plug_small_socket_outline:before {
  content: "\ef15";
}
.icon-plug_socket_entry:before {
  content: "\ef17";
}
.icon-plug_socket:before {
  content: "\ef18";
}
.icon-plug_type3a:before {
  content: "\ef19";
}
.icon-plug_us_tesla_powercharger:before {
  content: "\ef1a";
}
.icon-poi_all_categories_small:before {
  content: "\ef1e";
}
.icon-poi_all_categories:before {
  content: "\ef1f";
}
.icon-poi_car_limousine:before {
  content: "\ef20";
}
.icon-poi_car_sport:before {
  content: "\ef21";
}
.icon-poi_car_suv:before {
  content: "\ef22";
}
.icon-poi_listing_1:before {
  content: "\ef23";
}
.icon-poi_listing_2:before {
  content: "\ef24";
}
.icon-poi_listing_3:before {
  content: "\ef25";
}
.icon-poi_listing_4:before {
  content: "\ef26";
}
.icon-poi_listing_5:before {
  content: "\ef27";
}
.icon-poi_listing_6:before {
  content: "\ef28";
}
.icon-poi_listing_7:before {
  content: "\ef29";
}
.icon-poi_listing_8:before {
  content: "\ef2a";
}
.icon-poi_listing_9:before {
  content: "\ef2b";
}
.icon-poi_person:before {
  content: "\ef2d";
}
.icon-poi_small:before {
  content: "\ef2e";
}
.icon-points_of_interest:before {
  content: "\ef30";
}
.icon-pollution:before {
  content: "\ef31";
}
.icon-portal_connection:before {
  content: "\ef33";
}
.icon-predictive_maintanance:before {
  content: "\ef35";
}
.icon-process_history:before {
  content: "\ef3b";
}
.icon-propulsion_battery_failure:before {
  content: "\ef3c";
}
.icon-pure_mode_menu:before {
  content: "\ef3e";
}
.icon-quickfilter_communication_outline:before {
  content: "\ef3f";
}
.icon-quickfilter_communication:before {
  content: "\ef40";
}
.icon-race_new_lap:before {
  content: "\ef41";
}
.icon-reading_lamp_left:before {
  content: "\ef51";
}
.icon-reading_lamp_rear:before {
  content: "\ef52";
}
.icon-reading_lamp_right:before {
  content: "\ef53";
}
.icon-readout_pause:before {
  content: "\ef54";
}
.icon-rear_blind:before {
  content: "\ef56";
}
.icon-rear_fog_light:before {
  content: "\ef57";
}
.icon-rear_window_defrost_text:before {
  content: "\ef58";
}
.icon-rear_window_wiper:before {
  content: "\ef5a";
}
.icon-rear_window_wiping_water_wiper:before {
  content: "\ef5b";
}
.icon-rear_window_wiping_water:before {
  content: "\ef5c";
}
.icon-record:before {
  content: "\ef5d";
}
.icon-recuperation_level_1:before {
  content: "\ef5f";
}
.icon-recuperation_level_2:before {
  content: "\ef60";
}
.icon-recuperation_level_3:before {
  content: "\ef61";
}
.icon-recuperation_level_adaptive1:before {
  content: "\ef62";
}
.icon-refer_lap:before {
  content: "\ef64";
}
.icon-reference:before {
  content: "\ef65";
}
.icon-remote_digits:before {
  content: "\ef66";
}
.icon-remote:before {
  content: "\ef67";
}
.icon-reply:before {
  content: "\ef6b";
}
.icon-reset:before {
  content: "\ef6c";
}
.icon-restart:before {
  content: "\ef6d";
}
.icon-restaurant:before {
  content: "\ef6e";
}
.icon-retweet:before {
  content: "\ef6f";
}
.icon-roaming:before {
  content: "\ef72";
}
.icon-rtv_corner_view_front:before {
  content: "\ef74";
}
.icon-rtv_corner_view_rear:before {
  content: "\ef75";
}
.icon-rtv_front_view:before {
  content: "\ef76";
}
.icon-rtv_rear_view:before {
  content: "\ef78";
}
.icon-rtv_side_view:before {
  content: "\ef79";
}
.icon-save_ind:before {
  content: "\ef7b";
}
.icon-scroll_up_down:before {
  content: "\ef81";
}
.icon-sd_storage:before {
  content: "\ef82";
}
.icon-seat_belt_empty_seat:before {
  content: "\ef87";
}
.icon-seat_left_one:before {
  content: "\ef8f";
}
.icon-seat_settings:before {
  content: "\ef96";
}
.icon-settings_slider:before {
  content: "\ef9c";
}
.icon-skip_15_seconds_backward:before {
  content: "\efa2";
}
.icon-skip_15_seconds_forward:before {
  content: "\efa3";
}
.icon-smoking_forbidden:before {
  content: "\efa7";
}
.icon-smoking:before {
  content: "\efa8";
}
.icon-solar_panel:before {
  content: "\efb0";
}
.icon-speakerphone:before {
  content: "\efb6";
}
.icon-speller_arabian:before {
  content: "\efb7";
}
.icon-speller_china_pinyin:before {
  content: "\efb9";
}
.icon-speller_china_stroke:before {
  content: "\efba";
}
.icon-speller_greek:before {
  content: "\efbc";
}
.icon-speller_japan_hiragana:before {
  content: "\efbd";
}
.icon-speller_korea_jamo:before {
  content: "\efbe";
}
.icon-speller_russia:before {
  content: "\efc1";
}
.icon-speller_taiwan_zhuyin:before {
  content: "\efc2";
}
.icon-split_call:before {
  content: "\efc3";
}
.icon-sports_team:before {
  content: "\efc4";
}
.icon-star_half_filled:before {
  content: "\efc6";
}
.icon-start_stop_automatic:before {
  content: "\efc9";
}
.icon-statistics:before {
  content: "\efca";
}
.icon-steering_wheel_failure_red:before {
  content: "\efcb";
}
.icon-steering_wheel_failure:before {
  content: "\efcc";
}
.icon-stop:before {
  content: "\efcf";
}
.icon-stored_destinations:before {
  content: "\efd8";
}
.icon-sun_half_outline:before {
  content: "\efda";
}
.icon-sun_half:before {
  content: "\efdb";
}
.icon-sun_outline:before {
  content: "\efdc";
}
.icon-surround_5_1:before {
  content: "\efde";
}
.icon-system_settings:before {
  content: "\efe0";
}
.icon-tab:before {
  content: "\efe1";
}
.icon-tablet:before {
  content: "\efe2";
}
.icon-tailboard_j1_left:before {
  content: "\efe4";
}
.icon-tailboard_j1_right:before {
  content: "\efe5";
}
.icon-target_soc:before {
  content: "\efe6";
}
.icon-target:before {
  content: "\efe7";
}
.icon-tempomat_large:before {
  content: "\efe8";
}
.icon-thumb_up:before {
  content: "\efeb";
}
.icon-time_destination_duration:before {
  content: "\efec";
}
.icon-tire_pressure_menu:before {
  content: "\efee";
}
.icon-toggle_tubes_thin:before {
  content: "\efef";
}
.icon-toggle_tubes:before {
  content: "\eff0";
}
.icon-tone:before {
  content: "\eff1";
}
.icon-top_100:before {
  content: "\eff2";
}
.icon-tpeg:before {
  content: "\eff5";
}
.icon-traffic_antenna_j1:before {
  content: "\eff6";
}
.icon-traffic_antenna_limousine:before {
  content: "\eff7";
}
.icon-traffic_antenna_sport:before {
  content: "\eff8";
}
.icon-traffic_antenna_suv:before {
  content: "\eff9";
}
.icon-traffic_assist_limousine:before {
  content: "\effb";
}
.icon-traffic_assist_sport:before {
  content: "\effc";
}
.icon-traffic_assist_suv:before {
  content: "\effd";
}
.icon-traffic_j1:before {
  content: "\f000";
}
.icon-traffic_limousine:before {
  content: "\f001";
}
.icon-traffic_sign_recognition_limousine:before {
  content: "\f003";
}
.icon-traffic_sign_recognition_sport:before {
  content: "\f004";
}
.icon-traffic_sign_recognition_suv:before {
  content: "\f005";
}
.icon-traffic_sport:before {
  content: "\f006";
}
.icon-traffic_suv:before {
  content: "\f007";
}
.icon-traffic_ta_deactivate_tp:before {
  content: "\f008";
}
.icon-traffic_ta_off:before {
  content: "\f009";
}
.icon-traffic:before {
  content: "\f00a";
}
.icon-train_info:before {
  content: "\f00c";
}
.icon-trip_small:before {
  content: "\f011";
}
.icon-trip_till_destination:before {
  content: "\f012";
}
.icon-tunebar:before {
  content: "\f015";
}
.icon-tunnel_avoid:before {
  content: "\f016";
}
.icon-tv:before {
  content: "\f018";
}
.icon-tweets:before {
  content: "\f019";
}
.icon-twitter_retweet:before {
  content: "\f01a";
}
.icon-twitter_start:before {
  content: "\f01b";
}
.icon-twitter_trends:before {
  content: "\f01c";
}
.icon-twitter:before {
  content: "\f01d";
}
.icon-update_security_outline:before {
  content: "\f020";
}
.icon-vent_adjustment:before {
  content: "\f024";
}
.icon-vent_off:before {
  content: "\f025";
}
.icon-volume:before {
  content: "\f02c";
}
.icon-warning_standard_icon:before {
  content: "\f02e";
}
.icon-warning:before {
  content: "\f030";
}
.icon-way_point:before {
  content: "\f031";
}
.icon-wet_road_j1:before {
  content: "\f033";
}
.icon-wet_road_limousine:before {
  content: "\f034";
}
.icon-wet_road_sport:before {
  content: "\f035";
}
.icon-wet_road_suv:before {
  content: "\f036";
}
.icon-wind_deflector:before {
  content: "\f037";
}
.icon-windhield_wipers_speed:before {
  content: "\f039";
}
.icon-window_half_left:before {
  content: "\f03a";
}
.icon-window_half_right:before {
  content: "\f03b";
}
.icon-window_left:before {
  content: "\f03c";
}
.icon-window_right_disable:before {
  content: "\f03d";
}
.icon-window_right:before {
  content: "\f03e";
}
.icon-windows_front_back:before {
  content: "\f03f";
}
.icon-wiping_water:before {
  content: "\f040";
}
.icon-write:before {
  content: "\f042";
}
.icon-network_1:before {
  content: "\f043";
}
.icon-network_2:before {
  content: "\f044";
}
.icon-network_3:before {
  content: "\f045";
}
.icon-network_4:before {
  content: "\f046";
}
.icon-network_5:before {
  content: "\f047";
}
