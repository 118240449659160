@import "./global_variables";
@import "./global_mixins";

$list-item__singleline--padding: 3 * $grid__unit - $grid__unit / $grid__unit; // 11px
$list-item__opacity--disabled: 1;

$list-item__touch-height: 29 * $grid__unit; // 116px
$list-item__start-area-width: 28 * $grid__unit; // 112px
$list-item__end-area-width: 28.5 * $grid__unit; // 114px
$list-item__center-area-padding: 2.75 * $grid__unit $spacer__xs 2.25 * $grid__unit; // 11px 8px 9px
$list-item-end-margin: 4.5 * $grid__unit;
$list-item__gradient-color--1: #001d37;
$list-item__gradient-color--2: #06131c;

$list-item__active-gradient: $list-item__gradient-color--1 0, $list-item__gradient-color--2 50%,
  rgba($list-item__gradient-color--1, 0) 100%;

$list-item__start-area-width--tourplan-list: 26 * $grid__unit;
$list-item-start__width--dest-search-result-slideout: 23.5 * $grid__unit; // 94px
$list-item-start__margin-right--dest-search-result-slideout: -3.75 * $grid__unit; // -15px

$list-item-first__border-radius--map-view-options: $grid__unit - 1 $grid__unit - 1 0 0;
$list-item-last__border-radius--map-view-options: 0 0 $grid__unit - 1 $grid__unit - 1;

$list-item-divider__color--map-view-options: #585858;
$list-item-divider__width--map-view-options: 189 * $grid__unit; // 756px
$list-item-divider__margin--map-view-options: 0 ($spacer__m + 0.5 * $grid__unit) 0;
$list-item-divider__border--map-view-options: 0.5 * $grid__unit solid $list-item-divider__color--map-view-options;

$list-item-divider__top--tourplan-list: -0.5 * $grid__unit; // -2px

$list-item-last__border-left: 0.5 * $grid__unit solid $list-item-divider__color--map-view-options;

$list-center-content__width--tourplan-list: 101.5 * $grid__unit;
$list-center-content__padding--tourplan-list: 2.75 * $grid__unit $spacer__m 2.25 * $grid__unit 5 * $grid__unit;

$list-center-content__padding--multiple-routes: (2.75 * $grid__unit) (4 * $grid__unit) (2.25 * $grid__unit)
  (7 * $grid__unit); // 11px 16px 9px 28px
$list-center-content__padding--multiple-routes-arabic: (2.75 * $grid__unit) (7 * $grid__unit) (2.25 * $grid__unit)
  (4 * $grid__unit); // 11px 28px 9px 16px
$list-center-content__min-height--multiple-routes: $grid__row-height; // 118px

$list-center-content__height--multiple-routes-header: 24.25 * $grid__unit; // 97px

$list-center-content__padding--multiple-routes-header: 0 $spacer__m (2.25 * $grid__unit) (3.25 * $grid__unit); // 0 16px 9px 13px
$list-center-content__padding--multiple-routes-header-arabic: 0 (3.25 * $grid__unit) (2.25 * $grid__unit) $spacer__m; // 0 13px 9px 16px
$list-center-content__padding--pvs-routes-header: 0 $spacer__m 3.5 * $grid__unit 2.75 * $grid__unit; // 0 16px 14px 11px
$list-center-content__padding--pvs-routes-header-arabic: 0 2.75 * $grid__unit 3.5 * $grid__unit $spacer__m; // 0 11px 14px 16px

$list-center-content__padding--static-text-only-list-item: 2.75 * $grid__unit $spacer__m 2.25 * $grid__unit; // 11px 16px 9px;

$list-center-content__padding--phone-list: 2.75 * $grid__unit 11.25 * $grid__unit 2.25 * $grid__unit $spacer__m; // 11px 45px 9px 16px
$list-center-content__padding--phone-list-arabic: 2.75 * $grid__unit $spacer__m 2.25 * $grid__unit 45px; // 11px 16px 9px 45px
$list-center-content__padding--personal-routes: $list-center-content__padding--multiple-routes-header; // 0 16px 9px 13px

$list-center-content__padding--route-monitor-event: (1.75 * $grid__unit) $spacer__m (2.25 * $grid__unit) $size__s; // 7px 16px 9px 4px
$list-center-content__padding--route-monitor-event-arabic: (1.75 * $grid__unit) $size__s (2.25 * $grid__unit) $spacer__m; // 7px 4px 9px 16px

$list-item__min-height--alt-routes: 24.25 * $grid__unit; // 97px
$list-item__width--multiple-routes: 185 * $grid__unit; // 740px

$list-item-divider__width--poi-list-item: 184.5 * $grid__unit; // 738px
$list-item-divider__min-width--poi-list-item: 150.75 * $grid__unit; // 603px
// taken from poi-stack-list.component.scss
$list-item__poi-list-item-touchable-end--min-width: 28.25 * $grid__unit; // 113px

$list-item-start__width--tourplan-list-header: 23.75 * $grid__unit; // 95px
$list-item-start__width--poi-list: 21 * $grid__unit; // 84px

$list-item-end__height--multiple-routes-header: 22.75 * $grid__unit; // 91px
$list-item-end__margin-right--multiple-routes-header: -8.5 * $grid__unit; // -34px

$list-item-end__width--alt-routes-header: 24.25 * $grid__unit; // 97px
$list-item-end__width--dest-search-result-slideout: 26.5 * $grid__unit; // 106px

$list-touchable-full__min-width--tourplan-list-header: 126.25 * $grid__unit; // 505px
$list-touchable__margin-right--with-scroll-bar: 4.25 * $grid__unit; // 17px

$list-item__height--phone-list: 29.25 * $grid__unit; // 117px
$list-item-divider__width--phone-list: 121.5 * $grid__unit; // 486px
$list-item-divider__margin-left--phone-list: 3.5 * $grid__unit; // 14px
$list-item-divider__margin-right--phone-list: 3.5 * $grid__unit; // 14px
$list-item-divider__top--phone-list: -0.25 * $grid__unit; // -1px
$list-item-start__margin-left--phone-list: 5.5 * $grid__unit; // 22px
$list-item-start__margin-right--phone-list: $list-item-start__margin-left--phone-list; // 22px
$list-item-start__width--phone-list: 24 * $grid__unit; // 96px
$list-item-start__min-width--phone-list: 24 * $grid__unit; // 96px

$list-item-start__width--location-selected-expanded: 22.5 * $grid__unit; // 90px
$list-item-start__width--location-selected-expanded-arabic: 18.75 * $grid__unit; // 75px

$list-item-end__height--tourplan-list: 18 * $grid__unit; // 72px
$tourplan-list-item__first-half--width: 253.5 * $grid__unit; // 1014px

$divider__size: 0.5 * $grid__unit; // 2px

$list-item-end__min-width--list-item__nav-search: $spacer__xl + $divider__size; // 98px

$pressed-animated-container__margin: $divider__size; // 2px

$list-center-content__max-width--tire-control-menu: 133.5 * $grid__unit; // 534px

$list-item__background-color--detail-buttons: rgba(102, 102, 102, 0.5);
$list-item__margin-left--detail-buttons: 4.25 * $grid__unit; // 17px
$list-item__margin-right--deteail-buttons: 4.25 * $grid__unit; // 17px

$list-item__trip-counter-padding--horizontal: 8 * $grid__unit; // 32px
$list-item__trip-counter-offset--left: 4 * $grid__unit; // 16px
$list-item__trip-divider-length: calc(100% - 8 * #{$grid__unit});

$list-item__my-screen-drive-mode-select-tile-padding--horizontal: 8 * $grid__unit; // 32px

:host {
  display: block;
}

:host-context(.tourplan__list-item) {
  .list-item {
    justify-content: flex-start;
  }
}

.list-item--scroll-snap {
  scroll-snap-align: start;
}

.list-item {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-content: flex-start;
  width: 100%;
  font-size: $font__size--normal;
  min-height: $grid__row-height - 2px;
  padding-bottom: $divider__size;
  color: $font__color--primary;

  &::before,
  &::after {
    @include divider("standard", 98.5%);
  }

  &::after {
    display: none;
  }

  &.state-disabled {
    color: $color__disabled;
    opacity: $list-item__opacity--disabled;
  }

  &.state-collapsed {
    color: $font__color--primary-disabled;

    &::before {
      background-color: $color__white--15;
    }
  }

  &.state-active {
    @include gradient($list-item__active-gradient, radial, "50% 100%", "1424px 414px");
    overflow: visible;

    .list-touchable {
      &::after,
      &::before {
        @include divider("standard");
        display: block;
        left: 0;
        width: 100%;
        height: $size__overlay-border--3;
        background-color: $color__highlight;
      }

      &::before {
        top: -$size__overlay-border--3;
      }

      &::after {
        bottom: -$size__overlay-border--1;
      }

      &.is-first-item {
        &::before {
          display: none;
        }
      }

      &.state-focused {
        @include focus-state("list-item");

        .list-item-start,
        .list-item-end {
          color: $color__white--100;
        }

        &.state-active {
          &::before {
            display: block;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    .list-item-start {
      color: $color__white--100;
    }
  }

  &.is-first-item {
    &.state-focused.state-active {
      &::before {
        display: block;
      }
    }
  }
}

:host-context(.virtual-list) {
  .list-item {
    width: calc(100% - (#{$list-touchable__margin-right--with-scroll-bar}));
  }
}

.list-touchable {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  height: fit-content;
  min-height: $list-item__touch-height;
  min-width: 0;

  &--full {
    width: 100%;
    flex-shrink: 1;
  }

  &--end {
    &.state-focused {
      &::before {
        -webkit-margin-start: $divider__size; // sass-lint:disable-line no-vendor-prefixes
      }
    }
  }

  &.state-focused {
    @include focus-state("list-item");

    .list-item-start,
    .list-item-end {
      color: $color__white--100;
    }

    &.state-active {
      &::before {
        display: block;
      }

      &::after {
        display: none;
      }
    }
  }

  &.state-pressed {
    .list-item-start,
    .list-item-end {
      color: $color__white--100;
    }
  }
}

:host-context(.partial-popup__content--list) {
  .list-item {
    &::before,
    &::after {
      @include divider("overlay");
    }
  }
}

:host-context(.route-monitor-event) {
  .list-item {
    &.state-active {
      background: unset;

      .list-touchable {
        // sass-lint:disable-line nesting-depth
        &::after, // sass-lint:disable-line nesting-depth
        &::before {
          // sass-lint:disable-line nesting-depth
          display: none;
        }
      }
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .list-center-content {
    padding: $list-center-content__padding--route-monitor-event;
  }
}

:host-context(.#{$class-name-arabic-layout} .route-monitor-event) {
  // sass-lint:disable-line no-combinators
  .list-center-content {
    padding: $list-center-content__padding--route-monitor-event-arabic;
  }
}

.animated-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
}

.list-item-start {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: $list-item__touch-height;
  width: $list-item__start-area-width;
  min-width: $list-item__start-area-width;
  font-size: $size__m;
  text-align: center;
  box-sizing: content-box;
}

:host-context(.overview-screen__button) {
  .list-touchable {
    &.state-focused {
      &::before {
        // sass-lint:disable-line nesting-depth
        top: $divider__size;
      }
    }
  }
}

:host-context(.list__container--with-scroll-bar) {
  .list-touchable--base-list-item,
  .list-touchable--without-end,
  .list-touchable--end {
    &::before {
      -webkit-margin-end: $list-touchable__margin-right--with-scroll-bar; // sass-lint:disable-line no-vendor-prefixes
    }
  }
}

:host-context(.poi-list) {
  .list-item-start {
    width: $list-item-start__width--poi-list;
    min-width: unset;
  }
}

:host-context(.dest-search-result-slideout) {
  .list-item-start {
    width: $list-item-start__width--dest-search-result-slideout;
    min-width: unset;
    margin-right: $list-item-start__margin-right--dest-search-result-slideout;
  }

  .list-item-end {
    width: $list-item-end__width--dest-search-result-slideout;
    min-width: unset;
  }
}

:host-context(.#{$class-name-arabic-layout} .dest-search-result-slideout) {
  // sass-lint:disable-line no-combinators
  .list-item-start {
    margin-left: $list-item-start__margin-right--dest-search-result-slideout;
    margin-right: unset;
  }
}

:host-context(.list-item__nav-search) {
  .list-item-end {
    min-width: $list-item-end__min-width--list-item__nav-search;
  }
}

:host-context(.tourplan-list__item) {
  .list-touchable--full {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: $tourplan-list-item__first-half--width;
  }

  .list-touchable {
    flex-grow: 1;
  }

  .list-item-start {
    width: $list-item__start-area-width--tourplan-list;
    min-width: $list-item__start-area-width--tourplan-list;
  }

  .list-center-content {
    padding: $list-center-content__padding--tourplan-list;
    width: $list-center-content__width--tourplan-list;
  }

  .list-item-end__touch-divider {
    &::after {
      display: none;
    }
  }

  .list-item-end {
    width: 100%;
    justify-content: flex-start;
    -webkit-margin-end: unset; // sass-lint:disable-line no-vendor-prefixes
  }

  .list-item {
    &::after {
      display: none;
      top: $list-item-divider__top--tourplan-list;
    }
  }
}

:host-context(.overview-list) {
  .list-item {
    &::before {
      background-color: unset;
    }
  }
}

:host-context(.tourplan-list__header) {
  .list-item {
    &::before {
      background-color: unset;
    }
  }

  .list-item-start {
    width: $list-item-start__width--tourplan-list-header;
    min-width: $list-item-start__width--tourplan-list-header;
  }

  .list-touchable--full {
    min-width: $list-touchable-full__min-width--tourplan-list-header;
  }

  .list-item-end__touch-divider {
    &::after {
      display: none;
    }
  }
}

:host-context(.my-screen-tile__body--with-footer) {
  .list-item.is-last-item {
    &::before {
      display: none;
    }
  }
}

.list-item__dynamic-item-container {
  width: 100%;
}

.list-item-end {
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  height: $list-item__touch-height;
  // we can set a fixed width again when we can differentiate between
  // end areas with icon/cover/... and end areas with text
  // width: $list-item__end-area-width;
  min-width: $list-item__end-area-width;
  font-size: $size__m;
  box-sizing: content-box;
}

.list-center-content {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: $list-item__center-area-padding;
  overflow: hidden;
}

:host-context(.mib3-dragged) {
  .list-item {
    &::after {
      display: none;
    }
  }
}

:host-context(.mib3-dragged .tourplan-list__item) {
  // sass-lint:disable-line no-combinators
  .list-item {
    &::before {
      width: 100%;
    }
  }
}

:host-context(.#{$class-name-arabic-layout} .mib3-dragged) {
  // sass-lint:disable-line no-combinators
  direction: rtl;
}

:host-context(.device-list__item) {
  .list-center-content {
    padding: 0;
  }
}

:host-context(.map-view-options) {
  .list-item {
    &::before {
      width: $list-item-divider__width--map-view-options;
      margin: $list-item-divider__margin--map-view-options;
      box-shadow: unset;
      background-color: $list-item-divider__color--map-view-options;
    }

    &.is-last-item {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}

:host-context(.map-view-options__content) {
  .list-item.is-first-item {
    height: 30 * $grid__unit; // 120px

    .list-touchable {
      top: 0.5 * $grid__unit;
    }
  }
}

:host-context(.route__item),
:host-context(.pnav-routes__item),
.multiple-routes__list-item {
  .list-center-content {
    min-height: $list-center-content__min-height--multiple-routes;
    padding: $list-center-content__padding--multiple-routes;
  }

  .list-item-start {
    width: $spacer__m;
    min-width: $spacer__m;
    height: unset;
  }

  .list-item {
    padding-bottom: 0;
    padding-top: $divider__size;

    &::after {
      @include divider("overlay");
      bottom: unset;
      top: 0;
      left: 0;
      right: 0;
    }

    &::before {
      display: none;
    }

    &.is-first-item {
      &::after {
        top: 0;
      }
    }
  }

  .list-touchable {
    height: 100%;
  }
}

:host-context(.detail-buttons) {
  .list-item.is-last-item {
    &::after {
      display: none;
    }
  }

  .list-item {
    &::before {
      background-color: $list-item__background-color--detail-buttons;
      margin-left: $list-item__margin-left--detail-buttons;
      margin-right: $list-item__margin-right--deteail-buttons;
      box-shadow: none;
    }

    &::after {
      display: none;
    }
  }
}

:host-context(.#{$class-name-arabic-layout} .multiple-routes__list-item) {
  // sass-lint:disable-line no-combinators
  .list-center-content {
    padding: $list-center-content__padding--multiple-routes-arabic;
  }
}

:host-context(.#{$class-name-latin-layout} .slideout--one-and-a-half-tab) {
  // sass-lint:disable-line no-combinators
  .list-center-content {
    padding-right: 0;
  }
}

:host-context(.#{$class-name-arabic-layout} .slideout--one-and-a-half-tab) {
  // sass-lint:disable-line no-combinators
  .list-center-content {
    padding-left: 0;
  }
}

// Navi - Search - Quickfilter - static-text-only-elements
:host-context(.static-text-only-list-item) {
  .list-center-content {
    padding: $list-center-content__padding--static-text-only-list-item;
  }
}

:host-context(.phone-list) {
  .list-item {
    height: $list-item__height--phone-list;

    &::before {
      display: none;
    }

    &::after {
      @include divider("overlay");
      width: $list-item-divider__width--phone-list;
      margin-left: $list-item-divider__margin-left--phone-list;
      margin-right: $list-item-divider__margin-right--phone-list;
      top: $list-item-divider__top--phone-list;
    }

    &.is-first-item {
      &::after {
        display: none;
      }
    }
  }

  .list-item-start {
    margin-left: $list-item-start__margin-left--phone-list;
    width: $list-item-start__width--phone-list;
    min-width: $list-item-start__min-width--phone-list;
  }

  .list-center-content {
    padding: $list-center-content__padding--phone-list;
  }

  .list-item-end {
    display: none;
  }
}

:host-context(.#{$class-name-arabic-layout} .phone-list) {
  // sass-lint:disable-line no-combinators
  .list-item-start {
    margin-left: unset;
    margin-right: $list-item-start__margin-right--phone-list;
  }

  .list-center-content {
    padding: $list-center-content__padding--phone-list-arabic;
  }
}

:host-context(.call-list-container) {
  .list-item-start {
    &::before,
    &::after {
      color: unset;
    }
  }
}

:host-context(.slideout-container .dopi), // sass-lint:disable-line no-combinators
:host-context(.slideout-container .panorama-view-item) {
  // sass-lint:disable-line no-combinators
  .list-item {
    &::after {
      opacity: 1;
    }
  }
}

:host-context(.slideout-container),
:host-context(.tool-tip) {
  .list-item {
    &::before,
    &::after {
      @include divider("overlay");
    }

    &::before {
      display: none;
    }

    &.is-first-item {
      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }

  &.state-active {
    &:not(.state-focused) {
      background-image: none;
    }
  }

  &.state-focused {
    &::before {
      background-image: none;
    }
  }
}

:host-context(.destination-selected-expanded),
:host-context(.location-selected-expanded) {
  .list-item-start {
    width: $list-item-start__width--location-selected-expanded;
    min-width: $list-item-start__width--location-selected-expanded;
  }
}

:host-context(.#{$class-name-arabic-layout} .location-selected-expanded) {
  // sass-lint:disable-line no-combinators
  .list-item-start {
    width: $list-item-start__width--location-selected-expanded-arabic;
    min-width: $list-item-start__width--location-selected-expanded-arabic;
  }
}

// Universal rule
:host-context(.slide-out__content) {
  .list-item-start {
    width: 23 * $grid__unit; // 92px
    min-width: 23 * $grid__unit; // 92px
  }

  .list-center-content {
    padding-left: 0;
    padding-right: 0;
    -webkit-padding-end: $spacer__m; // sass-lint:disable-line no-vendor-prefixes
  }
}

:host-context(.route__item--last) {
  .list-item {
    &::before {
      display: none;
    }
  }
}

:host-context(.route__item:last-child) {
  .list-item {
    &::before {
      display: none;
    }
  }
}

:host-context(.pvs-routes__header),
:host-context(.alt-routes__header),
:host-context(.personal-routes__header) {
  .list-item {
    min-height: $list-item__min-height--alt-routes;
    height: $list-item__min-height--alt-routes;
    padding-bottom: 0;

    &::before {
      display: none;
    }
  }

  .list-touchable {
    min-height: $list-item__min-height--alt-routes;
    height: $list-item__min-height--alt-routes;
  }

  .list-center-content {
    height: $list-center-content__height--multiple-routes-header;
    padding: $list-center-content__padding--multiple-routes-header;
  }

  .list-item-end {
    height: unset;
    width: $list-item-end__width--alt-routes-header;
    min-width: $list-item-end__width--alt-routes-header;
  }
}

:host-context(.#{$class-name-arabic-layout} .personal-routes) {
  // sass-lint:disable-line no-combinators
  .list-item-end {
    margin-right: unset;
    margin-left: $list-item-end__margin-right--multiple-routes-header;
  }
}

:host-context(.#{$class-name-arabic-layout} .alt-routes__header) {
  // sass-lint:disable-line no-combinators
  .list-center-content {
    padding: $list-center-content__padding--multiple-routes-header-arabic;
  }

  .list-item-end {
    margin-right: unset;
  }
}

:host-context(.start-button) {
  .list-item {
    &::before,
    &::after {
      display: none;
    }
  }
}

:host-context(.list__item--checkbox),
:host-context(.list__item--radio) {
  .list-item.state-active {
    background-image: none;
  }

  .list-touchable.state-active {
    &:after,
    &:before {
      display: none;
    }
  }
}

:host-context(.default-scroll-bar-hidden) {
  .list-item.is-last-item {
    &:before {
      display: none;
    }
  }
}

// should probably be in an own component to use in center
.list-text {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 17px 32px;
  max-height: 116px;

  &__line-1 {
    font-size: 36px;
    line-height: 48px;

    &--top {
      transform: translateY(-18px);
    }
  }

  &__line-2 {
    font-size: 36px;
    height: 48px;
    line-height: 48px;
    margin-top: -1px;
    margin-bottom: -11px;
  }
}

// should probably be an own component for navi
.list-dopi {
  position: relative;
  width: 36px;
  font-size: 36px;
  display: inline-flex;
}

:host-context(.#{$class-name-arabic-layout} .pvs-routes__header) {
  // sass-lint:disable-line no-combinators
  .list-center-content {
    padding: $list-center-content__padding--pvs-routes-header-arabic;
  }

  .list-item-end {
    margin-right: unset;
    margin-left: $list-item-end__margin-right--multiple-routes-header;
  }
}

:host-context(.poi-list__item) {
  .list-item {
    &:after {
      width: $list-item-divider__width--poi-list-item;
    }
  }

  .list-touchable--full {
    min-width: $list-item-divider__min-width--poi-list-item;
  }

  .list-touchable--end {
    min-width: $list-item__poi-list-item-touchable-end--min-width;
  }

  .list-item-end__touch-divider {
    &::after {
      display: none;
    }
  }
}

:host-context(.#{$class-name-arabic-layout} .poi-list__item) {
  // sass-lint:disable-line no-combinators
  .list-item {
    &:after {
      right: 0;
    }
  }
}

:host-context(.warn-message-list) {
  // sass-lint:disable-line no-combinators
  .list-center-content {
    .#{$class-name-latin-layout} & {
      @include text-fading(left);
    }

    .#{$class-name-arabic-layout} & {
      @include text-fading(right);
    }
  }
}

:host-context(.tire-selection-item__tire-type-text--list-component) {
  .list-center-content {
    max-width: 100%;
  }
}

.selected-route {
  .list-item {
    @include gradient($list-item__active-gradient, radial, "50% 100%", "1424px 414px");
    padding-left: 0;
    padding-right: 0;

    .list-touchable {
      &::after, // sass-lint:disable-line nesting-depth
      &::before {
        @include divider("standard");
        display: block;
        left: 0;
        width: 100%;
        height: $size__overlay-border--3;
        background-color: $color__highlight;
      }

      &::before {
        // sass-lint:disable-line nesting-depth
        top: -$size__overlay-border--3;
      }

      &::after {
        // sass-lint:disable-line nesting-depth
        bottom: -$size__overlay-border--1;
      }

      &.is-last-item {
        // sass-lint:disable-line nesting-depth
        &::after {
          // sass-lint:disable-line nesting-depth
          bottom: 0;
        }
      }

      &.state-focused {
        // sass-lint:disable-line nesting-depth
        @include focus-state("list-item");

        .list-item-start, // sass-lint:disable-line nesting-depth
        .list-item-end {
          // sass-lint:disable-line nesting-depth
          color: $color__white--100;
        }

        &::after {
          // sass-lint:disable-line nesting-depth
          display: none;
        }
      }
    }
  }
}

:host-context(.my-screen-instance__bev-trip-host-tile) {
  .list-item--singleline {
    padding-left: $list-item__trip-counter-padding--horizontal;
    padding-right: $list-item__trip-counter-padding--horizontal;
  }
}

:host-context(.my-screen-instance__bev-trip-host-tile),
:host-context(.my-screen-instance__car-drive-mode-host-tile) {
  .list-item {
    &::before,
    &::after {
      @include divider("standard", $width: 100%, $padding: 4 * $grid__unit);
    }
  }
}

#{$selector-latin-layout} {
  .list-text {
    text-align: right;
  }

  .list-dopi {
    margin-left: 16px;
  }

  .list-item-end__touch-divider {
    @include icon-divider(left);
  }

  .animated-wrapper {
    left: $divider__size;
    right: 0;
  }
}

#{$selector-arabic-layout} {
  .list-text {
    text-align: left;
  }

  .list-dopi {
    margin-right: 16px;
  }

  .list-item-end__touch-divider {
    @include icon-divider(right);
  }

  .animated-wrapper {
    right: $divider__size;
    left: 0;
  }
}
