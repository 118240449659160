@import "../../../../../pagGlobalstyles/sass/global_variables";
@import "../../../../../pagGlobalstyles/sass/fonts";

$close-button__top: 0.5 * $grid__unit; // 2px
$close-button__right: 0.5 * $grid__unit; // 2px
$close-button__size: 25.5 * $grid__unit; // 102px
$close-button-icon__size: $size__m; // 60px

$destination-icon__width: $size__m; // 60px
$stop-icon__width: 45 * $grid__unit; // 180px

$tooltip__width: 187 * $grid__unit; // 748px
$tooltip-height--full-height: 174 * $grid__unit; // 696px
$tooltip__height--min: 24.5 * $grid__unit; // 98px
$tooltip__padding--expanded: (3.5 * $grid__unit) (6.5 * $grid__unit) 0 (4.5 * $grid__unit); // 14px 26px 0 18px
$tooltip__padding--expanded-arabic: (3.5 * $grid__unit) (4.5 * $grid__unit) 0 (6.5 * $grid__unit); // 14px 18px 0 26px
$tooltip__padding--latin: 0 $spacer__m 0 (5 * $grid__unit); // 0 16px 0 20px
$tooltip__padding--arabic: 0 (5 * $grid__unit) 0 $spacer__m; // 0 20px 0 16px
$tooltip__padding--default: 0 (4.5 * $grid__unit) 0 (4.5 * $grid__unit); // 0 18px 0 18px
$tooltip__margin--left: 33 * $grid__unit; // 132px

$second-maneuver__top: 27 * $grid__unit + 0.25 * $grid__unit; // 109px
$second-maneuver__height: 20 * $grid__unit; // 80px
$second-maneuver__padding: 0 ($spacer__xs--hor + $spacer__m); // 0 22px
$second-maneuver__top--override: 27 * $grid__unit + 0.25 * $grid__unit + $second-maneuver__height;
$second-maneuver__opacity: 0.9;

$guidance-active-next-maneuver__width: 193.5 * $grid__unit; // 774px
$guidance-active-next-maneuver__min-width--small: 65.25 * $grid__unit; // 261px
$guidance-active-next-maneuver__max-width--small: 71.5 * $grid__unit; // 286px
$guidance-active-next-maneuver__padding--right: $spacer__xs; // 8px
$guidance-info-second-maneuver__width: 64.25 * $grid__unit; // 257px

$guidance-active__opacity: 0.9;
$guidance-active__background-color: rgba(25, 25, 25, 1);
$guidance-active__background-image: rgba(25, 25, 25, 1);
$guidance-active__box-shadow2--tooltip: 0 10px 15px 0 rgba($color__primary--first, 0.3);
$guidance-active__margin: -0.25 * $grid__unit; // -1px

$list-item__before-background: rgba($color__white--40, 0.5);

$traffic-selected__height--min: $tooltip__height--min; // 98px
$traffic-selected__width: $guidance-active-next-maneuver__width; // 774px
$traffic-selected__padding: 0 (8.5 * $grid__unit) 0 (6 * $grid__unit - 0.25 * $grid__unit); // 0 34px 0 23px
$traffic-destination-selected-expanded__padding: 0 (6.5 * $grid__unit) 0 (5 * $grid__unit); // 0 26px 0 20px
$traffic-destination-selected-expanded__padding--arabic: 0 (5 * $grid__unit) 0 (6.5 * $grid__unit); // 0 20px 0 26px

$address-item-expanded__padding--left: $spacer__xs + 0.5 * $grid__unit; // 10px
$address-item-expanded__margin--right: 0.5 * $grid__unit; // 2px
$address-item-expanded__height: 56.5 * $grid__unit; // 226px
$address-item-expanded__width: 184 * $grid__unit; // 736px

$toggle-details-button__width: 28 * $grid__unit; // 112px
$toggle-details-button__width--poi-stack-selected: 25.5 * $grid__unit; // 102px

$toggle-details-button-icon__size: 18 * $grid__unit; // 72px

$toggle-details-button-divider__left: -0.5 * $grid__unit; // -2px
$toggle-details-button-divider__height: 14 * $grid__unit; // 56px

$location-selected__height: $tooltip__height--min; // 98px
$location-selected__width: $guidance-active-next-maneuver__width; // 774px

$location-selected__padding--latin: 0 0 0 $spacer__m; // 0 0 0 16px
$location-selected__padding--arabic: 0 $spacer__xs 0 0; // 0 8px 0 0;

$provider-icon__height: 10 * $grid__unit; // 40px
$provider-icon__width: 25 * $grid__unit; // 100px
$provider-icon__fontsize: 25 * $grid__unit; // 100px

$poi-stack__width: 193.5 * $grid__unit; // 774px
$poi-stack-selected__width: 193.5 * $grid__unit; // 774px

$poi-stack--expanded__height: 172 * $grid__unit; // 688px
$poi-stack--expanded__width: 193.5 * $grid__unit; // 774px

$tool-tip-poi-stack__padding-left: 8.25 * $grid__unit; // 33px
$tool-tip-poi-stack__padding-right: 10.25 * $grid__unit; // 41px

// Map Context colors
$map-tooltip__background: rgba(25, 25, 25, 0.9);
$map-tooltip__border-color: rgba(204, 204, 204, 0.1);

$map-tooltip__background: rgba(25, 25, 25, 0.9);
$map-tooltip__border: 1px solid $color__primary--first;
$map-tooltip__box-border: inset 0 0 0 2px $map-tooltip__border-color;
$map-tooltip__box-border--call-to-action: inset 0 0 0 2px rgba($color__negative-red, 0.5);
$map-tooltip__box-shadow: 0 10px 15px 0 rgba($color__primary--first, 0.3);

// Map Overlays
$map-control-bar__width: $grid__row-height - $grid__unit / 2; // 116px

$overlay__border-radius: $border__radius--default;

// Overlay background for Navigation overlays
@mixin overlay__background--navigation($outside-shadow: 0) {
  @include no-select;
  border-radius: $overlay__border-radius;
  background-color: $map-tooltip__background;
  border: $map-tooltip__border;

  @if $outside-shadow == 1 {
    box-shadow: $map-tooltip__box-border, $map-tooltip__box-shadow;
  } @else {
    box-shadow: $map-tooltip__box-border;
  }
}

.navigation-overlay-wrapper {
  display: flex;
  width: 100%;
  min-height: $tooltip__height--min;
  #{--boxed}: true;
}

.tool-tip {
  @include overlay__background--navigation(1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 0;
  height: auto;
  min-height: $tooltip__height--min;
  width: $tooltip__width;
  margin-top: $spacer__s;
  border-top-left-radius: $border__radius--default;
  border-bottom-left-radius: $border__radius--default;

  &--next-maneuver {
    position: relative;
    width: $guidance-active-next-maneuver__width;
    min-height: $tooltip__height--min;
    margin-top: 0;
    box-shadow: $map-tooltip__box-border, $map-tooltip__box-shadow;

    &.tool-tip--big {
      width: $guidance-active-next-maneuver__width;
    }

    &.tool-tip--small {
      width: auto;
      max-width: $guidance-active-next-maneuver__max-width--small;
      min-width: $guidance-active-next-maneuver__min-width--small;
    }
  }

  &--second-maneuver {
    @include overlay__background--navigation(0);
    top: $second-maneuver__top;
    width: $guidance-info-second-maneuver__width;
    min-height: $second-maneuver__height;
    margin-top: 0;
    padding: $second-maneuver__padding;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: $border__radius--default;
    border-bottom-left-radius: $border__radius--default;
    background-color: $color__white--20;
    opacity: $second-maneuver__opacity;
  }

  &--poi-stack {
    top: $size__xl;
    width: $poi-stack__width;
    min-height: $tooltip__height--min;
  }

  &--collapsed {
    height: $tooltip__height--min;
  }

  &--expanded {
    // #{$selector-latin-layout} & {
    padding: $tooltip__padding--expanded;
    // }

    #{$selector-arabic-layout} & {
      padding: $tooltip__padding--expanded-arabic;
    }
  }

  &--full-height {
    height: $tooltip-height--full-height;
  }

  .list-item {
    &::before,
    &::after {
      box-shadow: 0;
      text-shadow: none;
      background-color: $list-item__before-background;
    }
  }

  &.tool-tip--collapsed.state-focused {
    @include focus-state("default", "absolute");
  }
}

.poi-stack--selected {
  width: $poi-stack-selected__width;

  .toggle-details-button {
    width: $toggle-details-button__width--poi-stack-selected;
  }
}

.poi-stack--expanded {
  height: $poi-stack--expanded__height;
  width: $poi-stack--expanded__width;
}

.maneuver__override-position {
  top: $second-maneuver__top--override;
}

.navigation__wrapper {
  display: flex;
  position: absolute;
  top: $spacer__s;
  left: 0;
  width: 100%;
  min-height: $tooltip__height--min;
}

.pressed-animated-container__wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.guidance-active__button {
  display: flex;
  width: $stop-icon__width;
  height: $tooltip__height--min;
  border-top-left-radius: 0;
  border-top-right-radius: $border__radius--default;
  border-bottom-right-radius: $border__radius--default;
  border-bottom-left-radius: 0;
  border: $map-tooltip__border;
  box-shadow: $map-tooltip__box-border, $map-tooltip__box-shadow;
  overflow: hidden;
  opacity: $guidance-active__opacity;
  background-color: $guidance-active__background-color;
  font-size: $font__size--m;

  &.state-focused {
    @include focus-state(default, none, after);
  }
}

.address-item--expanded,
.traffic-info--expanded {
  display: flex;
  flex-shrink: 0;
  position: relative;
  height: $address-item-expanded__height;
  width: 100%;

  &::before {
    @include divider("overlay");
  }
}

.address-item--expanded {
  width: 100%;
}

.toggle-details-button {
  display: flex;
  flex-shrink: 0;
  align-self: center;
  width: $toggle-details-button__width;
  height: 100%;
  overflow: visible;

  &::after {
    height: $toggle-details-button-divider__height;
    width: unset;
    background-color: unset;
  }
}

.toggle-details-button__icon {
  width: $destination-icon__width;
  height: $destination-icon__width;
  font-size: $destination-icon__width;

  &::before {
    position: relative;
    text-shadow: none;
  }

  &::after {
    background-color: $map-tooltip__border-color;
  }
}

.cancel-button {
  position: absolute;
  top: $close-button__top;
  width: $close-button__size;
  height: $close-button__size;

  &.state-focused {
    @include focus-state(default, none, after);
  }
}

.cancel-button__icon {
  width: $close-button-icon__size;
  height: $close-button-icon__size;
  font-size: $close-button-icon__size;
  color: $color__white--60;

  &::before {
    text-shadow: none;
  }
}

.location-selected {
  height: $location-selected__height;
  width: $location-selected__width;

  &-expanded {
    width: $location-selected__width;
  }
}

.traffic-selected {
  width: $traffic-selected__width;
  min-height: $traffic-selected__height--min;
  padding: $traffic-selected__padding;
}

.traffic-selected-expanded {
  width: $traffic-selected__width;
}

// #{$selector-latin-layout} {
.tool-tip {
  padding: $tooltip__padding--latin;

  &--next-maneuver {
    padding-left: $guidance-active-next-maneuver__padding--right;
  }
}

.location-selected,
.poi-stack--selected {
  padding: 0;
}

.poi-stack--expanded {
  .cancel-button {
    right: $close-button__right;
  }
}

.toggle-details-button {
  @include icon-divider("left", $toggle-details-button-divider__left);
}

.location-selected-expanded {
  .address-item--expanded {
    padding-left: $address-item-expanded__padding--left;
  }

  .cancel-button {
    right: $close-button__right;
  }
}

.destination-selected-expanded,
.traffic-selected-expanded {
  padding: $traffic-destination-selected-expanded__padding;

  .cancel-button {
    position: absolute;
    right: $close-button__right;
  }
}
// }

#{$selector-arabic-layout} {
  .tool-tip {
    padding: $tooltip__padding--arabic;
    direction: rtl;

    &--next-maneuver {
      padding-right: $guidance-active-next-maneuver__padding--right;
    }
  }

  .location-selected,
  .poi-stack--selected {
    padding: 0;
  }

  .poi-stack--expanded {
    padding-right: $spacer__m;

    .cancel-button {
      left: $close-button__right;
    }
  }

  .toggle-details-button {
    @include icon-divider("right", $toggle-details-button-divider__left);
  }

  .address-item-expanded {
    direction: rtl;
  }

  .destination-selected-expanded,
  .traffic-selected-expanded {
    padding: $traffic-destination-selected-expanded__padding--arabic;

    .cancel-button {
      position: absolute;
      left: $close-button__right;
    }
  }

  .location-selected-expanded {
    .cancel-button {
      left: $close-button__right;
    }
  }
}

// #{$selector-steering-wheel-left} {
.tool-tip {
  left: 0;
  margin-left: $tooltip__margin--left;
}

.tool-tip--poi-stack {
  padding-left: $tool-tip-poi-stack__padding-left;
  padding-right: $tool-tip-poi-stack__padding-right;
}

.guidance-active__button {
  margin-left: $guidance-active__margin;
}
// }

#{$selector-steering-wheel-right} {
  .poi-stack--selected {
    margin-right: 0;
  }

  .tool-tip--poi-stack {
    padding-left: $tool-tip-poi-stack__padding-right;
    padding-right: $tool-tip-poi-stack__padding-left;
  }

  .tool-tip {
    right: 0;
    margin-right: $tooltip__margin--left;
  }

  .navigation__wrapper {
    flex-direction: row-reverse;
  }

  .guidance-active__button {
    margin-right: $guidance-active__margin;
  }
}
