// @import '../../../pagGlobalstyles/sass/icon-master';
@import "../../../pagGlobalstyles/sass/fonts";
@import "../../../pagGlobalstyles/sass/global_variables";

$icon__color--default: $color__white--80;

$results-list-end__divider-position: $spacer__ml;
$results-list-end__padding--left: 2 * $results-list-end__divider-position;

$icon-google-size__m: $icon-size__m; // 60px
$list-item-end-icon-margin: 6.5 * $grid__unit;

$poi__opacity: 0.8;
$quickfilter-icon__opacity: 0.4;

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon.state-pressed {
  color: $color__white--100;
}

.icon {
  @include font-pag-icon;
  // don't use icon-style mixin here, because the experimental features used inside break the rendering when icon type changes
  @include solid-icon-without-experimental-shit("enabled", 0);
  display: flex;
  height: 100%;
  width: 100%;
  background-size: contain;
  position: relative;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  align-items: center;
  justify-content: center;
  #{--boxed}: true;

  &::before,
  &::after {
    position: absolute;
  }

  &.state-pressed {
    // don't use icon-style mixin here, because the experimental features used inside break the rendering when icon type changes
    @include solid-icon-without-experimental-shit("pressed", 0);
  }

  &.blocking--darken {
    filter: brightness(10%);
  }

  &.blocking--remove {
    display: none;
  }

  &.blocking--hide {
    visibility: hidden;
  }

  &.state-disabled,
  :host-context(.state-disabled),
  :host-context(.state-collapsed) {
    @include solid-icon-without-experimental-shit("disabled", 0);
  }

  // Fix for SVG icons in disabled state
  // sass-lint:disable class-name-format
  &.state-disabled,
  :host-context(.state-disabled) {
    &.icon-contacts,
    &.icon-play_circle,
    &.icon-guidance_arrow,
    &.icon-magnify_glass_plus,
    // not in the icon master
    &.icon-speed_limit_60,
    &.icon-speed_limit_rain,
    &.icon-sirius_xm_no_signal,
    &.icon-sirius_xm,
    &.icon-network_0_arrow_forward,
    &.icon-network_1,
    &.icon-network_1_arrow_forward,
    &.icon-network_2,
    &.icon-network_2_arrow_forward,
    &.icon-network_3,
    &.icon-network_3_arrow_forward,
    &.icon-network_4,
    &.icon-network_4_arrow_forward,
    &.icon-network_5_arrow_forward,
    &.icon-view,
    &.icon-box,
    &.icon-box_checked,
    &.icon-comma_point,
    &.icon-day,
    // not in the icon master
    &.icon-indicator_warning,
    &.icon-processing,
    &.icon-radio_button,
    &.icon-radio_button_checked,
    &.icon-star_key,
    &.icon-today,
    // not in the icon master
    &.icon-weekend,
    // not in the icon master
    &.icon-point,
    // not in the icon master
    &.icon-warning_sign,
    &.icon-night_vision_off,
    // not in the icon master
    &.icon-emergency_assist_off,
    // not in the icon master
    &.icon-camera_panorama,
    // not in the icon master
    &.icon-climate_style_right,
    // not in the icon master
    &.icon-climate_style_left,
    // not in the icon master
    &.icon-air_distribution_focus,
    &.icon-air_distribution_diffuse,
    &.icon-ac_fan_only,
    &.icon-air_supply_right,
    // not in the icon master
    &.icon-air_supply_left,
    // not in the icon master
    &.icon-phone_pause_call,
    &.icon-phone_reactivate_call,
    &.icon-wheel,
    &.icon-arrow_n_button {
      opacity: $disabled-opacity;
    }
  }

  &.icon-processing {
    &::before {
      text-shadow: none;
    }
  }

  // sass-lint:enable class-name-format
  &.state-rotate-right {
    animation: rotate 1.5s linear infinite;
  }

  &.state-rotate-left {
    animation: rotate 1.5s linear reverse infinite;
  }

  &.icon-phone_missed_call {
    // sass-lint:disable-line class-name-format
    &::before {
      color: $color__negative-red;
      background-image: none;
    }

    &.state-pressed,
    &.state-active {
      &::before {
        color: $color__negative-red;
        background-image: none;
      }
    }

    &.state-disabled {
      &::before {
        color: $color__negative-red--disabled;
        background-image: none;
      }
    }
  }
}

.icon.icon--quickfilter-poi {
  opacity: $poi__opacity;
}

.icon.icon--quickfilter-item {
  width: $quickfilter__icon-size;
  height: $quickfilter__icon-size;

  &::before {
    text-shadow: none;
  }
}

:host-context(.tool-tip) {
  &::before {
    text-shadow: none;
  }
}

:host-context(.address__favorite-icon) {
  &::before {
    padding-left: $size__s;
  }
}

:host-context(.#{$class-name-arabic-layout} .address__favorite-icon) {
  // sass-lint:disable-line no-combinators
  &::before {
    padding-right: $size__s;
    padding-left: unset;
  }
}

:host-context(.list-headline__icon) {
  &.icon-processing {
    width: $size__m;
  }
}

.list-item {
  & .icon-processing {
    width: $size__m;
    align-self: center;
  }
}

.list-item-start {
  .icon {
  }
  .icon-small {
    width: 60px;
    height: 60px;
  }
  // disable drop-shadow to avoid paint flashing in lists
  @include solid-icon-without-experimental-shit("enabled", 0);
  font-size: $size__m;
}

:host-context(.list-item-start.state-disabled),
:host-context(.list-item-start.state-collapsed) {
  @include solid-icon-without-experimental-shit("disabled", 0);
}

:host-context(.list-item-start.state-pressed) {
  // don't use icon-style mixin here, because the experimental features used inside break the rendering when icon type changes
  @include solid-icon-without-experimental-shit("pressed", 0);
}

:host-context(.list-item-end) {
  // disable drop-shadow to avoid paint flashing in lists
  @include solid-icon-without-experimental-shit("enabled", 0);
  height: $size__m;
  width: $size__m;
  font-size: $size__m;
  margin: 0 $list-item-end-icon-margin;
}

:host-context(.list-item-end.state-disabled),
:host-context(.list-item-end.state-collapsed) {
  @include solid-icon-without-experimental-shit("disabled", 0);
}

:host-context(.list-item-end.state-pressed) {
  // don't use icon-style mixin here, because the experimental features used inside break the rendering when icon type changes
  @include solid-icon-without-experimental-shit("pressed", 0);
}

:host-context(.state-collapsed) {
  @include solid-icon-without-experimental-shit("disabled", 0);
}

:host-context(.state-active) {
  // don't use icon-style mixin here, because the experimental features used inside break the rendering when icon type changes
  @include solid-icon-without-experimental-shit("active", 0);
}

:host-context(.delete-button),
:host-context(.delete-button:before) {
  color: $color__white--80;
}

:host-context(.list-start-and-end) {
  width: $icon-size__m;
  height: 100%;

  &::before {
    height: $icon-size__m;
    font-size: $icon-size__m;
  }
}

.slideout-container {
  :host-context(.state-collapsed &) {
    @include solid-icon-without-experimental-shit("enabled", 0);
  }
}

.icon.icon-google_search {
  &::before,
  &::after {
    width: $icon-google-size__m;
    height: $icon-google-size__m;
  }
}

:host(.icon-google_search_inactive) {
  &::before,
  &::after {
    width: $icon-google-size__m;
    height: $icon-google-size__m;
  }
}

:host.icon-options_more {
  &.state-focused {
    @include focus-state;
    z-index: 1; //needs to lay on top of the quickfilter border.
  }

  &.state-pressed {
    @include icon-style("pressed", "solid", 0);

    &::before {
      -webkit-text-fill-color: $color__primary--second; // sass-lint:disable-line no-vendor-prefixes
      background-image: none;
    }
  }
}

.icon--hidden {
  display: none;
}

.icon__url {
  width: 100%;
  height: auto;
}

.state-disabled .icon.quickfilter-icon--with-url {
  // sass-lint:disable-line no-combinators
  .icon__url {
    opacity: $quickfilter-icon__opacity;
  }
}

:host-context(.media-browser-list-item-cover) {
  .icon__url {
    width: 100%;
  }
}

:host-context(.maintenance__list-item) {
  &.icon-check {
    &::before {
      color: $color__positive-green;
    }
  }
}

.icon.rdk-warning {
  &::before,
  &::after {
    color: $color__focus-orange;
  }
}

.icon.rdk-critical {
  &::before,
  &::after {
    color: $color__negative-red;
  }
}

.icon-context.readout-active {
  &::before {
    color: $color__active;
  }
}

.icon-context.darkener {
  &::before,
  &::after {
    color: $color__white--40;
  }

  .icon__url {
    opacity: $disabled-opacity;
  }
}

.icon.list-item-icon-url {
  .icon__url {
    width: $icon-size__m; // 60px
    height: auto;
  }
}
