@import "../../../pagGlobalstyles/sass/global_variables";
@import "../../../pagGlobalstyles/sass/fonts";

$color--full-transparent: rgba(0, 0, 0, 0);
$color--transparent: rgba(0, 0, 0, 0.9);
$color--flat: rgba(0, 0, 0, 1);

$overlay-container__margin-top--details-screen: $spacer__ml; // 24px

$overlay-text__max-width: 170 * $grid__unit; // 680px
$overlay-text__max-width--details-screen: 121.5 * $grid__unit; // 486px
$overlay-text__line-height: $font__line-height--s;
$overlay-text__max-height: $overlay-text__line-height;
$overlay-text__max-height--details-screen: 2 * $overlay-text__line-height;
$overlay-text__font-size: $font__size--normal;

.blockable-container-wrapper {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  #{--boxed}: false;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &--full-transparent {
    background-color: $color--full-transparent;
  }

  &--transparent {
    background-color: $color--transparent;
  }

  &--flat {
    background-color: $color--flat;
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__icon {
    width: $icon-size__xl;
    height: $icon-size__xl;
    margin: 0 auto $spacer__xs;
    font-size: $icon-size__xl;
    color: $color__normal;
  }

  &__text {
    @include font-pag-plain;
    max-width: $overlay-text__max-width;
    max-height: $overlay-text__max-height;
    font-size: $overlay-text__font-size;
    line-height: $overlay-text__line-height;
    text-align: center;
    color: $color__normal;
    overflow: hidden;
    white-space: nowrap;
  }
}

:host-context(.split-container--detail-screen) {
  .overlay__container {
    margin-top: $overlay-container__margin-top--details-screen;
  }

  .overlay__text {
    width: $overlay-text__max-width--details-screen;
    max-height: $overlay-text__max-height--details-screen;
    white-space: unset;
  }
}
